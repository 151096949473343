import React from 'react';
import './BotaoSimular.css';
import { withRouter } from 'react-router-dom'
import { removeVeiculo } from "../../config/local";


class BotaoSimular extends React.Component {

    simular = async anuncio  =>{
        await this.props.history.push('/simular/formulario')
    }

    render(){
        return (
            this.props.show?
            <button onClick={ () => this.simular()} className="botaoSimular">
                Simule seu <br/>financiamento
            </button> : <div></div>
        );
    }

}

export default withRouter(BotaoSimular);
