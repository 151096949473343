import React from 'react'
import "./Favoritos.css"
import {BASE_URL_DJANGO} from "../../../config/api";
import axios from 'axios'
import Load from "../../../components/loading/load";
import { getUser } from "../../../config/local";
import InfiniteScroll from "react-infinite-scroll-component";
import Card from "../../../components/card/Card";
import fundo from "../../../assets/images/fundo_1.png";

class Favoritos extends React.Component{

    state ={
        loading: false,
        favoritos: [],
        user: {},
        page: 1,
        total_pages: 0
    }

    componentDidMount = async ()  => {
        await this.getUser()
        await this.getFavoritos()
    }

    mudarPagina = num =>{
        num = this.state.page + 1
        this.setState({ page: num }, () =>{
            this.getAnuncioP(num);
        });
    }

    getFavoritos = (page = 1) =>{
        axios.get(`${BASE_URL_DJANGO}/anuncios/favoritos/${this.state.user.id}/`)
            .then(res =>{
                this.setState({ favoritos: res.data.data, total_pages: res.data.total_pages})
            })
            .catch(err =>{
                console.log(err)
            })
    }
    getUser = async () =>{
        const user = await getUser();
        await this.setState({ user: user })
    }

    removerFavorito = (id) =>{
        this.setState({ loading: true })
        axios.delete(`${BASE_URL_DJANGO}/favoritos/remove/${id}/${this.state.user.id}/`).then(res => {
            alert('O anúncio foi removido da sua lista de favoritos.')
            this.setState({ loading: false })
            this.componentDidMount();
        }).catch(err =>{
            alert('Não foi possível realizar a operação. Por favor tente novamente mais tarde.');
            this.setState({ loading: false})
        })
    }

    render() {
        return(
            <>
                <img src={fundo} className={"imgBackground"} alt=""/>
            <div id={"favoritos"}>
                <Load loading={this.state.loading}/>
                <div className={" bg-white divFavoritos"}>
                    <h3 className={"text-info ml-2 "}>Lista de favoritos</h3>
                    <InfiniteScroll
                        dataLength={this.state.favoritos.length}
                        // next={this.mudarPagina}
                        // hasMore={true}
                        // loader={<h4>Carregando...</h4>}
                        endMessage={
                            <p style={{textAlign: 'center', marginTop: '30px'}}>
                                <b>Todos os resultados exibidos.</b>
                            </p>
                        }>
                        <div className={"divFav"}>
                            {this.state.favoritos.map((anuncio, index) => (
                                <div className={"d-flex flex-column divFavorito m-2"} key={index}>
                                    <Card  dados={anuncio}/>
                                    <button onClick={() => this.removerFavorito(anuncio.id, anuncio.user)} className={'btnRemoverFav btn-warning'}>Remover</button>
                                </div>
                            ))}
                        </div>

                    </InfiniteScroll>
                </div>
                <div>
          <img height="1" width="1"
            src="https://www.facebook.com/tr?id=1438661459663519?ev=PageView?noscript=1" />
        </div>
            </div>
                </>
        )
    }
}

export default Favoritos