import React, {useState } from 'react'
import './Geral.css'
import moto from '../../../assets/images/moto.png'

import carro from '../../../assets/images/carro.png'
import caminhao from '../../../assets/images/caminhao.png'
import barco from '../../../assets/images/barco.png'
import capa from '../../../assets/images/capa.png'
import memiliacapa from '../../../assets/images/foto_capa_homem.png'
import googlePlay from '../../../assets/images/PlayStore.png'
import memilia from '../../../assets/images/foto_mulher_procurar_carro.png'
import contudobanner from '../../../assets/images/banner_consorcio1.png'
import appStore from '../../../assets/images/AppleStore.png'
import Card from '../../../components/card/Card'
import axios from 'axios'
import { BASE_URL_DJANGO, BASE_URL_PHP } from '../../../config/api'
import Pagination from "react-pagination-library";
import "react-pagination-library/build/css/index.css";
import { Accordion, Button, Modal } from "react-bootstrap";
import { getUser, getToken, verificarDispositivo, setMobile, removeVeiculo } from "../../../config/local";
import CurrencyInput from "react-currency-input";
import BotaoSimular from "../../../components/botaosimular/BotaoSimular";
import Load from '../../../components/loading/load'
import InfiniteScroll from 'react-infinite-scroll-component';
import Select from 'react-select'
import IntlCurrencyInput from "react-intl-currency-input";
import Drawer from "@material-ui/core/Drawer";
import { pedirPermissaoParaReceberNotificacoes } from '../../../config/push-notifications';



class Geral extends React.Component {

  state = {
    page: 1,
    next_page: 0,
    last_page: 0,
    total_pages: 0,
    anuncios: [],
    marcas: [],
    modelos: [],
    caracteristicas: [],
    idmarca: 0,
    codfipe: 0,
    km: 0,
    kminicial: 0,
    kmfinal: 0,
    vinicial: 0,
    vfinal: 0,
    caractSelec: [],
    palavras: '',
    filtrado: false,
    busca: '',
    filters: [],
    collapse: false,
    width: 0,
    height: 0,
    encontrar: false,
    /// Quero Encontrar
    idmarcaquero: 0,
    modeloquero: 0,
    marcaquero :0,
    ano: "",
    valor: "",
    flautomatico: true,
    loja: false,
    observacao: "",
    logged: false,
    loading: false,
    modelosquero: [],
    marcasquero:[],
    versoesquero: [],
    moreData: true,
    items: Array.from({ length: 20 }),
    linkloja: '',
    marcaFiltro: 0,
    modeloFiltro: 0,
    categoriaFiltro: 0,
    filtrando: true,
    filtrar: false,
    atualcategoria: 0,
    listacategoria: []

  }

  fetchMoreData = () => {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    setTimeout(() => {
      this.setState({
        items: this.state.items.concat(Array.from({ length: 20 }))
      });
    }, 1500);
  };

  addFiltro = (filtro, init = true) => {

    const filtros = this.state.filters.filter(f => {
      return f.nome !== filtro.nome
    })
    filtros.push(filtro)
    if (init) {
      this.setState({ filters: filtros }, () => {
        this.getAnuncioP(1, true, filtros)
      })
    } else {
      this.setState({ filters: filtros })
    }
  }
  removeFiltro = (nome) => {
    const filtros = this.state.filters.filter(f => {
      return f.nome !== nome
    })
    this.setState({ filters: filtros })
  }

  getAnuncioP = (num, filtrado = false, filtros = []) => {
    this.setState({ loading: true })
    let busca = '?';

    if (filtros.length > 0) {
      filtros = filtros.map(item => {
        if (item.nome === 'kminicial' || item.nome === 'kmfinal' || item.nome === 'vinicial' || item.nome === 'vfinal') {
          if (typeof item.mask === "number") return item
          item.mask = item.mask.replace(".", "").replace(",", ".");
        }
        return item;
      })
      filtros.forEach(item => busca += `${item.nome}=${item.valor || item.mask}&`);

    }
    else {
      this.state.filters.forEach(item => busca += `${item.nome}=${item.valor}&`);
    }


    this.setState({ busca: busca }, () => {
      let url = `${BASE_URL_DJANGO}/anuncios/${this.state.busca}page=${num}`;

      axios.get(url)
        .then(res => {
          this.setState({ moreData: true, loading: false })
          this.setState({ last_page: res.data.last_page, total_pages: res.data.total_pages })
          this.setState({ anuncios: num == 1 ? res.data.data : this.state.anuncios.concat(res.data.data), anunciosFiltrados: num == 1 ? res.data.data : this.state.anunciosFiltrados.concat(res.data.data) })
          // if (window.innerWidth < 720 ){
          //   this.setState({ filtrar: false })
          // }
        })
        .catch(err => {
          this.setState({ moreData: false, loading: false })
        })
    });
  }

  getlistacategoria = () => {

    this.state.listacategoria = [
      {
        Label: "Carros",
        Value: "1"
      },
      {
        Label: "Motos",
        Value: "2"
      },
      {
        Label: "Caminhões e Micro-Ônibus",
        Value: "3"
      }
    ];
  }

  adicionaOuRemove = (filtro) => {
    const filtros = this.state.filters.filter(f => {
      return (f.nome === filtro.nome)
    });
    if (filtros.length > 0) {
      const remove = this.state.filters.filter(f => {
        return (f.nome !== filtro.nome)
      })
      this.setState({ filters: remove })
    } else {
      const add = this.state.filters;
      add.push(filtro)
      this.setState({ filters: add })
    }
  }


  getAnuncios = () => {
    this.setState({ loading: true });
    let url = `${BASE_URL_DJANGO}/anuncios/?page=${this.state.page}`;
    let filtros = [
      { nome: 'idmarca', valor: this.state.idmarca },
      { nome: 'codfipe', valor: this.state.codfipe },
      { nome: 'km', valor: this.state.km },
      { nome: 'vinicial', valor: parseInt(this.state.vinicial) },
      { nome: 'vfinal', valor: parseInt(this.state.vfinal) }
    ]
    filtros = filtros.filter(item => item.valor !== 0);


    if (filtros.length > 1) {
      this.setState({ page: 1 })
      filtros = filtros.map((item, idx) => {
        let filtro = '';
        if (idx === 0) {
          filtro = `?${item.nome}=${item.valor}`
        } else {
          filtro = `&${item.nome}=${item.valor}`
        }
        return filtro

      })
      let url = `${BASE_URL_DJANGO}/anuncios/`;

      url = `${url}${filtros.join().replace(',', '')}`
      url = url.replace(",", "");

      if (this.state.caractSelec.length > 0)
        url += "&caracteristicas=" + this.state.caractSelec.toLocaleString();
    }
    if (filtros.length === 1) {
      let filtro = '?' + filtros[0].nome + '=' + filtros[0].valor
      url = `${url}${filtro}`
    }
    axios.get(url)
      .then(res => {
        this.setState({ last_page: res.data.last_page, total_pages: res.data.total_pages })
        this.setState({ anuncios: res.data.data, anunciosFiltrados: res.data.data })
        this.setState({ loading: false })
      })
      .catch(err => this.setState({ loading: false }))
  }

  getMarcas = (categoria) => {
    if (categoria == 0) {
      // alert('Selecione uma categoria válida')
      // this.state.filters.shift();

      this.setState({ modeloFiltro: 0, marcaFiltro: 0, categoriaFiltro: 0,idmarcaquero :0,modeloquero:0,codfipe2:0 })
      this.setState({ filters: [], busca: '' }, () => {
        this.getAnuncioP(1);
      })
    } else {
      axios.get(`${BASE_URL_DJANGO}/fipe/carros/marcas/${categoria}/`)
        .then(res => {
          this.setState({ marcas: res.data.data,marcasquero:res.data.data, categoriaFiltro: categoria ,idmarcaquero : categoria})
          this.setState.atualcategoria = categoria;
          this.addFiltro({ nome: 'tipo_veiculo', valor: categoria })
        })
        .catch(err => console.log(err))
    }
    this.getAnuncioP(1);
  }

  getModelos = (marca) => {
    if (parseInt(marca) === 0) {
      this.setState({ modeloFiltro: 0, marcaFiltro: 0, idmarcaquero:0,modeloquero:0  })
      this.setState({ filters: [], busca: '' }, () => {
        this.getAnuncioP(1);
      })
    } else {
      this.setState({ modelos: [] }, () => {
        axios.get(`${BASE_URL_DJANGO}/fipe/carros/veiculos/${marca}/${this.setState.atualcategoria}/`)
          .then(res => {
            this.setState({ modelos: res.data.data.Modelos, marcaFiltro: marca , marcaquero : marca})
            this.addFiltro({ nome: 'idmarca', valor: marca })
          })
          .catch(err => console.log(err))
      })

    }
  }

  getModelosQuero = marca => {
    if (parseInt(marca) === 0) {
      this.setState({ modeloFiltro: 0, marcaFiltro: 0,idmarcaquero:0,modeloquero:0,codfipe2:0  })
      this.setState({ filters: [], busca: '' }, () => {
        this.getAnuncioP(1);
      })
    } else {
      this.setState({ modelos: [] }, () => {
        axios.get(`${BASE_URL_DJANGO}/fipe/carros/veiculos/${marca}/${this.setState.atualcategoria}/`)
          .then(res => {
            this.setState({ modelosquero: res.data.data.Modelos, idmarcaquero: marca })
          })
          .catch(err => {
            alert('Não foi possível encontrar os modelos para a marca selecionada. Tente novamente mais tarde.')
          })

      })
    }
  }
  getVersaoQuero = modelo => {
    if (modelo === 0) {
      this.setState({ modeloFiltro: 0, marcaFiltro: 0,idmarcaquero:0,modeloquero:0,codfipe2 :0  })
      this.setState({ filters: [], busca: '' }, () => {
        this.getAnuncioP(1);
      })
    } else {
      axios.get(`${BASE_URL_DJANGO}/fipe/carros/veiculo/${this.state.idmarcaquero}/${modelo}/${this.setState.atualcategoria}/`)
        .then(res => {
          this.setState({ modeloquero: modelo, versoesquero: res.data.data })
        })
        .catch(err => { alert("Não foi possível encontrar versões para o modelo selecionado. Tente novamente mais tarde.") })
    }
  }

  getCaracteristicas = async () => {
    axios.get(`${BASE_URL_DJANGO}/extra/carasteristicas/`)
      .then(res => {
        this.setState({ caracteristicas: res.data })
      })
      .catch(err => console.log(err))
  }

  buscaLivre = () => {
    const busca = document.getElementById('busca');
    if (busca.value.trim() === '') {
      return this.getAnuncios()
    }
    const filtro = {
      nome: 'search',
      valor: busca.value
    }
    this.addFiltro(filtro);
  }

  buscaFiltros = () => {
    if (this.state.palavras.trim() === '') {
      return this.getAnuncios()
    }
    const filtro = {
      nome: 'search',
      valor: this.state.palavras
    }
    this.addFiltro(filtro);
  }

  filtrarCarac = (id) => {
    let caracteristicas = this.state.caractSelec;
    const index = caracteristicas.indexOf(parseInt(id));


    if (index !== -1) {
      caracteristicas.splice(index, 1);
    } else {
      caracteristicas.push(parseInt(id));
    }

    if (index !== 0) {
      console.log("entrou aqui no if");
      this.setState({ caractSelec: caracteristicas }, () => {
        console.log(this.state.caractSelec.toLocaleString())
        this.addFiltro({
          nome: 'caracteristicas', valor: this.state.caractSelec.toLocaleString()
        })
      });
    }
    else {
      console.log("entrou aqui no else");
      this.state.filters.pop();
      this.addFiltro(this.state.filters);
      console.log(this.state.filters);

    }

  }
  componentDidMount = () => {
    const width = window.innerWidth
    const user = getUser();
    if (user !== null) {
      this.setState({ logged: true })
    }
    if (width < 720) {
      this.toggle()
    }
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    const input = document.getElementById("busca");
    const me = this;
    input.addEventListener("keyup", function (event) {
      // Number 13 is the "Enter" key on the keyboard
      if (event.keyCode === 13) {
        // Cancel the default action, if needed
        event.preventDefault();
        // Trigger the button element with a click
        me.buscaLivre()
      }
    });
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

 

  abrirModal = () => {
    if (this.state.logged) {
      this.setState({ encontrar: true })
    } else {
      alert('É necessário estar logado para realizar essa ação.')
    }
  }

  fecharModal = () => {
    this.setState({ encontrar: false })
    this.setState({
      modeloquero: '',
      idmarcaquero: '',
      codfipe: '',
      codfipe2: '',
      valor: '',
      flautomatico: true,
      observacao: ''
    })
  }
  fecharLoja = async () => {

    await this.setState({ loja: false })
    await setMobile({
      mobile: 1
    })
  }

  componentWillMount = () => {
    this.getlistacategoria()
    this.verificarDispositivo()
    this.getCaracteristicas()
    this.getAnuncios()

    removeVeiculo()
  }

  filtrarModelo = (fipe) => {
    this.setState({ modeloFiltro: fipe })
    this.addFiltro({ nome: 'codfipe', valor: fipe })
  }

  mudarPagina = num => {
    num = this.state.page + 1
    this.setState({ page: num }, () => {
      this.getAnuncioP(num);
    });
  }

  collapse = () => {
    return !this.state.collapse ?
      "geralFiltros p-4" : "d-none"

  }

  updateWindowDimensions = () => {
    if ((window.innerWidth < 720 && this.state.width < 720)) {
      this.setState({ filtrando: false });
      return
    } else {
      this.setState({ width: window.innerWidth, height: window.innerHeight }, () => {
        if (this.state.width < 720) {
          this.setState({ filtrando: false })
        } else {
          this.setState({ filtrando: true })
        }
      });
    }
  }

  queroComprar = async () => {
    const user = await getUser();
    const carro = await {
      idmarca: this.state.idmarcaquero,
      modelo: this.state.modeloquero,
      codfipe: this.state.modeloquero,
      codfipe2: this.state.codfipe2,
      valor: this.state.valor.replace(".", "").replace(",", "."),
      flautomatico: this.state.flautomatico,
      observacao: this.state.observacao,
      user_id: user.id
    }
    const token = getToken()
    const config = {
      headers: { Authorization: `${token}` }
    };
    axios.post(`${BASE_URL_DJANGO}/quero-comprar/`, carro, config)
      .then(res => {
        alert('Sua solicitação foi realizada com sucesso, assim que encontrarmos um veículo para você, entraremos em contato.')
        this.setState({ encontrar: false })
        this.getAnuncios()
        this.setState({
          modeloquero: '',
          idmarcaquero: '',
          codfipe: '',
          codfipe2: '',
          valor: '',
          flautomatico: true,
          observacao: ''
        })
        const busca = document.getElementById('busca');
        busca.value = ''
      })
      .catch(err => {
        alert('Não foi possível realizar a solicitação. Por favor, veja se todos os campos foram preenchidos corretamente.')
      })
  }

  toggle = () => {
    // if(!this.state.collapse){
    //   const filtros = document.getElementById("filtros").style.width
    //
    //   this.setState({ collapse : true}, () =>{
    //     document.getElementsByClassName("geralDivAnunciados")[0].style.marginLeft = "0px";
    //     document.getElementById("filtros").style.display = "none";
    //     document.getElementById("filtros").style.width = "0px";
    //   })
    // }else{
    //   this.setState({ collapse : false}, () =>{
    //     document.getElementsByClassName("geralDivAnunciados")[0].style.marginLeft = "320px";
    //     document.getElementById("filtros").style.display = "block";
    //     document.getElementById("filtros").style.width = "300px";
    //   })
    //
    // }
  }

  verificarDispositivo = async () => {

    let userAgent = await navigator.userAgent.toLowerCase();
    let devices = await new Array('iphone', 'android');
    const mobile = await verificarDispositivo()
    if (mobile) {
      return
    }
    function mobiDetect(userAgent, devices) {
      for (var i = 0; i < devices.length; i++) {
        if (userAgent.search(devices[i]) > 0) {
          return devices[i];
        }
      }
      return null;
    }
    let device = await mobiDetect(userAgent, devices);
    if (device !== null) {
      await device == 'iphone' ? this.setState({ linkloja: 'https://apps.apple.com/br/app/vende-tudo/id1499526187' }) : this.setState({ linkloja: 'https://play.google.com/store/apps/details?id=br.com.vendetudo.vende_tudo_app' })
      await this.setState({ loja: true })

    } else {
      await this.setState({ loja: false })
    }
  }

  render() {
    return (
      <div className="geral">
        <Load loading={this.state.loading} />
        {/*<button onClick={pedirPermissaoParaReceberNotificacoes}>Pedir Permissão</button>*/}
        <React.Fragment key={true}>
          <Drawer anchor={'left'} open={this.state.filtrar} onClose={() => this.setState({ filtrar: false })}>
            <div className=" d-flex divFiltroMob">
              <button type={"button"} className={"btn btn-sm btn-danger px-4 my-2 ml-2"} onClick={() => this.setState({ filtrar: false })}><i className={'fas fa-arrow-left'}></i></button>
              <h6 className={"p-2 ml-2 pt-3"}>Filtros</h6>
            </div>
            <div className={"p-4 mt-4"}>
              <div className="geralLine w-100">
                <input id={"buscaPalavra"} value={this.state.palavras} type="text" onChange={text => this.setState({ palavras: text.target.value })}
                  placeholder="Buscar por palavra-chave" />
                <button className="geralLente" onClick={() => this.buscaFiltros()}>
                  <i className="fas fa-search"></i>
                </button>
              </div>
              <div className="geralFiltroCheck">
                <input type="checkbox" />
                <label>Procurar pelo título do anúncio</label>
              </div>
              <h5 className="py-3">Carros, vans e utilitários</h5>
              <Select
                value={this.state.categoriaFiltro !== 0 ? this.state.listacategoria.filter(m => m.Value === this.state.categoriaFiltro).map(m1 => { return { value: m1.Value, label: m1.Label } }) : ''}
                isClearable={true}
                className={"my-1"}
                placeholder={"Categoria"}
                options={this.state.listacategoria.map(it => { return { value: it.Value, label: it.Label } })}
                onChange={categoria => this.getMarcas(categoria !== null ? categoria.value : 0)}
              />

              <Select
                value={this.state.marcaFiltro !== 0 ? this.state.marcas.filter(m => m.Value === this.state.marcaFiltro).map(m1 => { return { value: m1.Value, label: m1.Label } }) : ''}
                isClearable={true}
                className={"my-1"}
                placeholder={"Marca"}
                options={this.state.marcas.map(it => { return { value: it.Value, label: it.Label } })}
                onChange={marca => {
                  this.getModelos(marca !== null ? marca.value : 0)
                }}
              />

              <Select
                value={this.state.modeloFiltro !== 0 ? this.state.modelos.filter(m => m.Value === this.state.modeloFiltro).map(m1 => { return { value: m1.Value, label: m1.Label } }) : ''}
                className={"my-1"}
             
                placeholder={"Modelo"}
                options={this.state.modelos.map(it => { return { value: it.Value, label: it.Label } })}
                onChange={modelo => this.filtrarModelo(modelo.value)}
              />
              <div className="geralFiltroMinMax text-left">
                <label>Quilometragem (Min. & Max.)</label>
                <div className="d-flex align-items-center">
                  {/*<IntlCurrencyInput*/}
                  {/*    value={this.state.kminicial}*/}
                  {/*    className="form-control mx-1 inputFiltro"*/}
                  {/*    onChange={(e,v,mask) => this.setState({ kminicial: mask }, ()=>{*/}
                  {/*      if(v === '') return this.removeFiltro( 'kminicial')*/}
                  {/*      this.addFiltro({ nome:'kminicial' , mask },false)*/}
                  {/*    })*/}
                  {/*    }*/}
                  {/*    currency="BRL" config={{*/}
                  {/*  locale: "pt-BR",*/}
                  {/*  formats: {*/}
                  {/*    number: {*/}
                  {/*      BRL: {*/}
                  {/*        currency: "BRL",*/}
                  {/*        minimumFractionDigits: 2,*/}
                  {/*        maximumFractionDigits: 2,*/}
                  {/*      },*/}
                  {/*    },*/}
                  {/*  }*/}
                  {/*}}*/}
                  {/*/>*/}
                  <IntlCurrencyInput
                    value={this.state.kminicial}
                    className="form-control mx-1 inputFiltro"
                    onChange={(e, v, mask) => this.setState({ kminicial: mask }, () => {
                      if (mask === '') return this.removeFiltro('kminicial')
                      this.addFiltro({ nome: 'kminicial', mask }, false)
                    })}
                    currency="BRL" config={{
                      locale: "pt-BR",
                      formats: {
                        number: {
                          BRL: {
                            currency: "BRL",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          },
                        },
                      }
                    }}
                  />
                  <IntlCurrencyInput
                    value={this.state.kmfinal}
                    className="form-control mx-1 inputFiltro"
                    onChange={(e, v, mask) => this.setState({ kmfinal: mask }, () => {
                      if (mask === '') return this.removeFiltro('kmfinal')

                      this.addFiltro({ nome: 'kmfinal', mask }, false)
                    })}
                    currency="BRL" config={{
                      locale: "pt-BR",
                      formats: {
                        number: {
                          BRL: {
                            currency: "BRL",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          },
                        },
                      }
                    }}
                  />
                  {/*<CurrencyInput maxlength="10" allowEmpty={true} decimalSeparator={","} thousandSeparator="." value={this.state.kminicial} onChange={v => this.setState({ kminicial: v}, () =>{*/}
                  {/*  if(v === '') return this.removeFiltro( 'kminicial')*/}
                  {/*  this.addFiltro({ nome:'kminicial' , v},false)*/}
                  {/*})} className="form-control mx-1 inputFiltro"  />*/}
                  {/*<CurrencyInput maxlength="10" allowEmpty={true} decimalSeparator={","} thousandSeparator="." value={this.state.kmfinal} onChange={v => this.setState({ kmfinal: v }, () =>{*/}
                  {/*  if( v=== '') return this.removeFiltro( 'kmfinal')*/}

                  {/*  this.addFiltro({ nome:'kmfinal' , v},false)*/}
                  {/*})} className="form-control mx-1 inputFiltro" />*/}
                  {/*<input type={"number"} onChange={v => this.addFiltro({ nome:'kminicial' , valor: v.target.value},false)} className="form-control mx-1 inputFiltro"*/}
                  {/*       placeholder="Min." />*/}
                  {/*<input type={"number"} onChange={v => this.addFiltro({nome: 'kmfinal', valor:v.target.value},false)} className="form-control mx-1 inputFiltro"*/}
                  {/*       placeholder="Max."/>*/}
                  <button type="button" className="btn" onClick={() => this.getAnuncioP(1, true, this.state.filters)}><i
                    className="fas fa-search"></i></button>
                </div>
              </div>
              <div className="geralFiltroMinMax text-left">
                <label>Preço (Min. & Max.)</label>

                <div className="d-flex align-items-center">
                  {/*<CurrencyInput allowEmpty={true} decimalSeparator="," thousandSeparator="." value={this.state.km} className="form-control mx-1 inputFiltro" placeholder={"Min."} onChangeEvent={ v => this.addFiltro({nome: 'vinicial', valor:v.target.value}, false)}/>*/}
                  <IntlCurrencyInput
                    value={this.state.vinicial}
                    className="form-control mx-1 inputFiltro"
                    onChange={(e, v, mask) => this.setState({ vinicial: mask }, () => {
                      if (mask === '') return this.removeFiltro('vinicial')
                      this.addFiltro({ nome: 'vinicial', mask }, false)
                    })}
                    currency="BRL" config={{
                      locale: "pt-BR",
                      formats: {
                        number: {
                          BRL: {
                            currency: "BRL",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        },
                      }
                    }}
                  />
                  {/*<CurrencyInput maxlength="20" allowEmpty={true} decimalSeparator={","} thousandSeparator="." value={this.state.vinicial} onChange={v => this.setState({ vinicial: v }, () =>{*/}
                  {/*  if(v === '') return this.removeFiltro( 'vinicial')*/}
                  {/*  this.addFiltro({ nome:'vinicial' , v},false)*/}
                  {/*})} className="form-control mx-1 inputFiltro" />*/}
                  <IntlCurrencyInput
                    value={this.state.vfinal}
                    className="form-control mx-1 inputFiltro"
                    onChange={(e, v, mask) => this.setState({ vfinal: mask }, () => {
                      if (mask === '') return this.removeFiltro('vfinal')
                      this.addFiltro({ nome: 'vfinal', mask }, false)
                    })}
                    currency="BRL" config={{
                      locale: "pt-BR",
                      formats: {
                        number: {
                          BRL: {
                            currency: "BRL",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        },
                      }
                    }}
                  />
                  {/*<CurrencyInput maxlength="20" allowEmpty={true} decimalSeparator={","} thousandSeparator="." value={this.state.vfinal} onChange={v => this.setState({ vfinal: v }, () =>{*/}
                  {/*  if(v === '') return this.removeFiltro( 'vfinal')*/}
                  {/*  this.addFiltro({ nome:'vfinal' , v},false)*/}
                  {/*})} className="form-control mx-1 inputFiltro" />*/}
                  {/*<input type={"number"} onChange={v => this.addFiltro({nome: 'vinicial', valor:v.target.value}, false)} className="form-control mx-1 inputFiltro"*/}
                  {/*       placeholder="Min."/>*/}
                  {/*<input type={"number"} onChange={v => this.addFiltro({nome: 'vfinal', valor:v.target.value}, false)} className="form-control mx-1 inputFiltro"*/}
                  {/*       placeholder="Max.."/>*/}
                  <button className="btn" onClick={() => this.getAnuncioP(1, true, this.state.filters)}><i className="fas fa-search"></i></button>
                </div>
              </div>
              <div className="geralFiltroSelect text-left">
                <label>Caracteristicas</label>
                {this.state.caracteristicas.map(ca =>
                  <div key={ca.id} className="form-group form-check">
                    <input type="checkbox" className="form-check-input" checked={this.state.caractSelec.includes(ca.id)} value={ca.id}
                      onChange={car => this.filtrarCarac(car.target.value)} />
                    <label className="form-check-label">{ca.nome}</label>
                  </div>
                )}
              </div>
              <div className="geralFiltroMinMax mt-2 text-left">
                <label>Baixe o Aplicativo</label>
                <div className={"divApp"}>

                  <a target={"_blank"} href={"https://play.google.com/store/apps/details?id=br.com.vendetudo.vende_tudo_app"} className={"w-100 my-2 appContent"}>
                    <div>
                      <img src={googlePlay} width={100} alt="" />
                    </div>
                    <div className={" appLoja "}>
                      <div>
                        <i className={"fab fa-google-play fa-2x mx-2"}></i>
                      </div>
                      <div> DISPONÍVEL NA <br /> GooglePlay</div>
                    </div>

                  </a>

                  <a target={"_blank"} href={"https://apps.apple.com/br/app/vende-tudo/id1499526187"} className={"w-100 my-2 appContent"} style={{ backgroundColor: '#F96C0F' }}>
                    <div>
                      <img src={appStore} width={100} alt="" />
                    </div>
                    <div className={"appLoja"}>
                      <div>
                        <i className={"fab fa-apple fa-3x mx-2"} ></i>
                      </div>
                      <div> DISPONÍVEL NA <br /> AppStore</div>
                    </div>

                  </a>

                </div>

              </div>
              <div className="geralFiltroMinMax mt-2 text-left">
                <label>Precisa de ajuda?</label>
                <div className={"divApp"}>

                  <a href={"/faleconosco"} className={"w-100 my-2 appContent"}>
                    <div className={" appLoja appFale "}>
                      <div>
                        <i className={"fas fa-comment-alt fa-2x mx-2"}></i>
                      </div>
                      <div> Fale Conosco</div>
                    </div>
                  </a>

                </div>

              </div>
            </div>
          </Drawer>
        </React.Fragment>

        <div className="backCapa">
          <img src={capa} className="img-fluid" />
          <img src={memiliacapa} className="imgHomem d-none d-lg-block" />
          <div className="divBusca">
            <h1 className="my-1 text-white textobaner">Encontre o veículo dos seus sonhos</h1>
            <div className="geralLine">
              <input type="text" id="busca" placeholder="Estou procurando por..." className="inputGeral" />
              <button className="geralLente geralLente1" onClick={() => this.buscaLivre()}>
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="w-100 text-left">
          <button className={` btnExpandir btn   ${!this.state.filtrar ? 'btn-warning' : 'btn-danger'}`} onClick={() => this.setState({ filtrar: !this.state.filtrar })} id="filtrar">
            {!this.state.filtrar ? 'Filtrar' : 'Fechar'}
          </button>
        </div>
        <div className="divCentral d-flex justify-content-center">

          {/*<div className={`${this.collapse()} ${this.state.filtrando ? 'd-block ' : ' d-none '}` } id="filtros">*/}
          <div className={`${this.collapse()} d-none d-md-block`} id="filtros">
            <div>
              <div className="geralLine">
                <input id={"buscaPalavra"} type="text" onChange={text => this.setState({ palavras: text.target.value })}
                  placeholder="Buscar por palavra-chave" />
                <button className="geralLente" onClick={() => this.buscaFiltros()}>
                  <i className="fas fa-search"></i>
                </button>
              </div>
              <div className="geralFiltroCheck">
                <input type="checkbox" />
                <label>Procurar pelo título do anúncio</label>
              </div>
              <h5 className="py-3">Carros, vans e utilitários</h5>
              <Select
                value={this.state.categoriaFiltro !== 0 ? this.state.listacategoria.filter(m => m.Value === this.state.categoriaFiltro).map(m1 => { return { value: m1.Value, label: m1.Label } }) : ''}
                isClearable={true}
                className={"my-1"}
                placeholder={"Categoria"}
                options={this.state.listacategoria.map(it => { return { value: it.Value, label: it.Label } })}
                onChange={categoria => this.getMarcas(categoria !== null ? categoria.value : 0)}
              />
              <Select
                value={this.state.marcaFiltro !== 0 ? this.state.marcas.filter(m => m.Value === this.state.marcaFiltro).map(m1 => { return { value: m1.Value, label: m1.Label } }) : ''}
                isClearable={true}
                className={"my-1"}
                placeholder={"Marca"}
                options={this.state.marcas.map(it => { return { value: it.Value, label: it.Label } })}
                onChange={marca => this.getModelos(marca !== null ? marca.value : 0)}
              />

              <Select
                value={this.state.modeloFiltro !== 0 ? this.state.modelos.filter(m => m.Value === this.state.modeloFiltro).map(m1 => { return { value: m1.Value, label: m1.Label } }) : ''}
                className={"my-1"}
                placeholder={"Modelo"}
                options={this.state.modelos.map(it => { return { value: it.Value, label: it.Label } })}
                onChange={modelo => this.filtrarModelo(modelo.value)}
              />
              <div className="geralFiltroMinMax text-left">
                <label>Quilometragem (Min. & Max.)</label>
                <div className="d-flex align-items-center">
                  {/*<IntlCurrencyInput*/}
                  {/*    value={this.state.kminicial}*/}
                  {/*    className="form-control mx-1 inputFiltro"*/}
                  {/*    onChange={(e,v,mask) => this.setState({ kminicial: mask }, ()=>{*/}
                  {/*      if(v === '') return this.removeFiltro( 'kminicial')*/}
                  {/*      this.addFiltro({ nome:'kminicial' , mask },false)*/}
                  {/*    })*/}
                  {/*    }*/}
                  {/*    currency="BRL" config={{*/}
                  {/*  locale: "pt-BR",*/}
                  {/*  formats: {*/}
                  {/*    number: {*/}
                  {/*      BRL: {*/}
                  {/*        currency: "BRL",*/}
                  {/*        minimumFractionDigits: 2,*/}
                  {/*        maximumFractionDigits: 2,*/}
                  {/*      },*/}
                  {/*    },*/}
                  {/*  }*/}
                  {/*}}*/}
                  {/*/>*/}
                  <IntlCurrencyInput
                    value={this.state.kminicial}
                    className="form-control mx-1 inputFiltro"
                    onChange={(e, v, mask) => this.setState({ kminicial: mask }, () => {
                      if (mask === '') return this.removeFiltro('kminicial')
                      this.addFiltro({ nome: 'kminicial', mask }, false)
                    })}
                    currency="BRL" config={{
                      locale: "pt-BR",
                      formats: {
                        number: {
                          BRL: {
                            currency: "BRL",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          },
                        },
                      }
                    }}
                  />
                  <IntlCurrencyInput
                    value={this.state.kmfinal}
                    className="form-control mx-1 inputFiltro"
                    onChange={(e, v, mask) => this.setState({ kmfinal: mask }, () => {
                      if (mask === '') return this.removeFiltro('kmfinal')

                      this.addFiltro({ nome: 'kmfinal', mask }, false)
                    })}
                    currency="BRL" config={{
                      locale: "pt-BR",
                      formats: {
                        number: {
                          BRL: {
                            currency: "BRL",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          },
                        },
                      }
                    }}
                  />
                  {/*<CurrencyInput maxlength="10" allowEmpty={true} decimalSeparator={","} thousandSeparator="." value={this.state.kminicial} onChange={v => this.setState({ kminicial: v}, () =>{*/}
                  {/*  if(v === '') return this.removeFiltro( 'kminicial')*/}
                  {/*  this.addFiltro({ nome:'kminicial' , v},false)*/}
                  {/*})} className="form-control mx-1 inputFiltro"  />*/}
                  {/*<CurrencyInput maxlength="10" allowEmpty={true} decimalSeparator={","} thousandSeparator="." value={this.state.kmfinal} onChange={v => this.setState({ kmfinal: v }, () =>{*/}
                  {/*  if( v=== '') return this.removeFiltro( 'kmfinal')*/}

                  {/*  this.addFiltro({ nome:'kmfinal' , v},false)*/}
                  {/*})} className="form-control mx-1 inputFiltro" />*/}
                  {/*<input type={"number"} onChange={v => this.addFiltro({ nome:'kminicial' , valor: v.target.value},false)} className="form-control mx-1 inputFiltro"*/}
                  {/*       placeholder="Min." />*/}
                  {/*<input type={"number"} onChange={v => this.addFiltro({nome: 'kmfinal', valor:v.target.value},false)} className="form-control mx-1 inputFiltro"*/}
                  {/*       placeholder="Max."/>*/}
                  <button type="button" className="btn" onClick={() => this.getAnuncioP(1, true, this.state.filters)}><i
                    className="fas fa-search"></i></button>
                </div>
              </div>
              <div className="geralFiltroMinMax text-left">
                <label>Preço (Min. & Max.)</label>

                <div className="d-flex align-items-center">
                  {/*<CurrencyInput allowEmpty={true} decimalSeparator="," thousandSeparator="." value={this.state.km} className="form-control mx-1 inputFiltro" placeholder={"Min."} onChangeEvent={ v => this.addFiltro({nome: 'vinicial', valor:v.target.value}, false)}/>*/}
                  <IntlCurrencyInput
                    value={this.state.vinicial}
                    className="form-control mx-1 inputFiltro"
                    onChange={(e, v, mask) => this.setState({ vinicial: mask }, () => {
                      if (mask === '') return this.removeFiltro('vinicial')
                      this.addFiltro({ nome: 'vinicial', mask }, false)
                    })}
                    currency="BRL" config={{
                      locale: "pt-BR",
                      formats: {
                        number: {
                          BRL: {
                            currency: "BRL",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        },
                      }
                    }}
                  />
                  {/*<CurrencyInput maxlength="20" allowEmpty={true} decimalSeparator={","} thousandSeparator="." value={this.state.vinicial} onChange={v => this.setState({ vinicial: v }, () =>{*/}
                  {/*  if(v === '') return this.removeFiltro( 'vinicial')*/}
                  {/*  this.addFiltro({ nome:'vinicial' , v},false)*/}
                  {/*})} className="form-control mx-1 inputFiltro" />*/}
                  <IntlCurrencyInput
                    value={this.state.vfinal}
                    className="form-control mx-1 inputFiltro"
                    onChange={(e, v, mask) => this.setState({ vfinal: mask }, () => {
                      if (mask === '') return this.removeFiltro('vfinal')
                      this.addFiltro({ nome: 'vfinal', mask }, false)
                    })}
                    currency="BRL" config={{
                      locale: "pt-BR",
                      formats: {
                        number: {
                          BRL: {
                            currency: "BRL",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        },
                      }
                    }}
                  />
                  {/*<CurrencyInput maxlength="20" allowEmpty={true} decimalSeparator={","} thousandSeparator="." value={this.state.vfinal} onChange={v => this.setState({ vfinal: v }, () =>{*/}
                  {/*  if(v === '') return this.removeFiltro( 'vfinal')*/}
                  {/*  this.addFiltro({ nome:'vfinal' , v},false)*/}
                  {/*})} className="form-control mx-1 inputFiltro" />*/}
                  {/*<input type={"number"} onChange={v => this.addFiltro({nome: 'vinicial', valor:v.target.value}, false)} className="form-control mx-1 inputFiltro"*/}
                  {/*       placeholder="Min."/>*/}
                  {/*<input type={"number"} onChange={v => this.addFiltro({nome: 'vfinal', valor:v.target.value}, false)} className="form-control mx-1 inputFiltro"*/}
                  {/*       placeholder="Max.."/>*/}
                  <button className="btn" onClick={() => this.getAnuncioP(1, true, this.state.filters)}><i className="fas fa-search"></i></button>
                </div>
              </div>
              <div className="geralFiltroSelect text-left">
                <label>Caracteristicas</label>
                {this.state.caracteristicas.map(ca =>
                  <div key={ca.id} className="form-group form-check">
                    <input type="checkbox" className="form-check-input" value={ca.id} checked={this.state.caractSelec.includes(ca.id)}
                      onChange={car => this.filtrarCarac(car.target.value)} />
                    <label className="form-check-label">{ca.nome}</label>
                  </div>
                )}
              </div>
              <div className="geralFiltroMinMax mt-2 text-left">
                <label>Baixe o Aplicativo</label>
                <div className={"divApp"}>

                  <a target={"_blank"} href={"https://play.google.com/store/apps/details?id=br.com.vendetudo.vende_tudo_app"} className={"w-100 my-2 appContent"}>
                    <div>
                      <img src={googlePlay} width={100} alt="" />
                    </div>
                    <div className={" appLoja "}>
                      <div>
                        <i className={"fab fa-google-play fa-2x mx-2"}></i>
                      </div>
                      <div> DISPONÍVEL NA <br /> GooglePlay</div>
                    </div>

                  </a>

                  <a target={"_blank"} href={"https://apps.apple.com/br/app/vende-tudo/id1499526187"} className={"w-100 my-2 appContent"} style={{ backgroundColor: '#F96C0F' }}>
                    <div>
                      <img src={appStore} width={100} alt="" />
                    </div>
                    <div className={"appLoja"}>
                      <div>
                        <i className={"fab fa-apple fa-3x mx-2"} ></i>
                      </div>
                      <div> DISPONÍVEL NA <br /> AppStore</div>
                    </div>

                  </a>

                </div>

              </div>
              <div className="geralFiltroMinMax mt-2 text-left">
                <label>Precisa de ajuda?</label>
                <div className={"divApp"}>

                  <a href={"/faleconosco"} className={"w-100 my-2 appContent"}>
                    <div className={" appLoja appFale "}>
                      <div>
                        <i className={"fas fa-comment-alt fa-2x mx-2"}></i>
                      </div>
                      <div> Fale Conosco</div>
                    </div>
                  </a>

                </div>

              </div>
            </div>
          </div>
          <div className="geralDivAnunciados px-4" id="anuncios">

            <div className={"fundo_banner_consorcio"} id="bannerconsorcio">
              <a href="/consorcio" class="linkbanner">
                <img src={contudobanner} height={150} className={"imagenconsorcio"} alt="" />
              </a>
            </div>

            <div className={"corpo_carros"}>
              {this.state.anuncios.length > 0 ?
                <InfiniteScroll
                  dataLength={this.state.anuncios.length}
                  next={this.mudarPagina}
                  hasMore={this.state.moreData}
                  loader={<h4>Carregando...</h4>}
                  endMessage={
                    <div className={"text-center"} >
                      <button className="mr-2 btn btn-warning btnQueroEncontrar" onClick={() => this.abrirModal()}>Quero que encontrem pra mim</button>
                    </div>
                  }
                >
                  {this.state.anuncios.map((anuncio, index) => (
                    <Card key={index} dados={anuncio} />
                  ))}
                </InfiniteScroll>
                : <div className="text-center d-block d-md-flex flex-row w-100 col-md-12 col-12">
                  <div className="col-md-8 col-12 margemtoptextonaoencontrado">
                    <div className={"d-flex flex-row d-md-none"}>
                      <img src={memilia} className={"mulherEncontrar"} alt="" />
                      <h3 className="py-1 textoEncontrar text-justify ">Não foi possível encontrar veículos com esses parâmetros de busca. Podemos procurar junto a outros anunciantes para você!</h3>
                    </div>
                    <div className={"d-md-none"}>
                      {
                        this.state.logged ? <button className="mr-2  mt-1 btn btn-warning btn-block py-2 " onClick={() => this.abrirModal()}>Quero que encontrem pra mim</button> :
                          <a className="mr-2 btn mt-1 btn-outline-info btnfundo " href="/login">Faça login</a>
                      }
                      {/*<button className="ml-2 btn btn-warning mb-5" onClick={() =>{*/}
                      {/*  this.setState({ busca: '?', filters: [], page: 1}, () =>{*/}
                      {/*    document.getElementById('busca').value = ""*/}
                      {/*    document.getElementById('buscaPalavra').value = ""*/}
                      {/*    this.getAnuncioP(1);*/}
                      {/*  })*/}
                      {/*}} >Voltar</button>*/}
                    </div>
                    <h3 className="py-3 d-none d-md-block textoEncontrar ">Não foi possível encontrar veículos com esses parâmetros de busca. Podemos procurar junto a outros anunciantes para você!</h3>
                    {
                      this.state.logged ? <button className="mx-auto btn btn-warning btnQueroEncontrar d-none d-md-block " onClick={() => this.abrirModal()}>Quero que encontrem pra mim</button> :
                        <a className="mr-2 d-none d-md-block btn btn-outline-info w-25 mx-auto " href="/login">Faça login</a>
                    }
                    {/*<span className={"d-none d-md-block"}>ou</span>*/}
                    {/*<button className="mx-auto btn btn-warning d-none d-md-block" onClick={() =>{*/}
                    {/*  this.setState({ busca: '?', filters: [], page: 1}, () =>{*/}
                    {/*    document.getElementById('busca').value = ""*/}
                    {/*    document.getElementById('buscaPalavra').value = ""*/}
                    {/*    this.getAnuncioP(1);*/}
                    {/*  })*/}
                    {/*}} >Voltar</button> <br/>*/}

                  </div>
                  <div className="col-md-4 col-sm-4 col-12">
                    <img src={memilia} height={280} className={"mulherEncontrar d-none d-md-block"} alt="" />
                  </div>
                </div>

              }
            </div>
            <>
              <Modal show={this.state.encontrar} onHide={this.fecharModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Encontrar Veículo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form>
                    <label htmlFor="idmarca">Categoria</label>
                    <Select
                      value={this.state.categoriaFiltro !== 0 ? this.state.listacategoria.filter(m => m.Value === this.state.categoriaFiltro).map(m1 => { return { value: m1.Value, label: m1.Label } }) : ''}
                      isClearable={true}
                      className={"my-1"}
                      placeholder={"Categoria"}
                      options={this.state.listacategoria.map(it => { return { value: it.Value, label: it.Label } })}
                      onChange={categoria => this.getMarcas(categoria !== null ? categoria.value : 0)}
                    />    

                    <label htmlFor="idmarca">Marca</label>
                    <Select
                      value={this.state.idmarcaquero !== 0 ? this.state.marcasquero.filter(m => m.Value === this.state.idmarcaquero).map(m1 => { return { value: m1.Value, label: m1.Label } }) : ''}
                      className=" my-1 select2 "
                      isClearable={true}
                      placeholder={"Selecione..."}
                      options={this.state.marcasquero.map(it => { return { value: it.Value, label: it.Label } })}
                      onChange={marca => this.getModelosQuero(marca !== null ? marca.value : 0)}
                    />
                    <label htmlFor="idmarca">Modelo</label>
                    <Select
                      value={this.state.modeloquero !== 0 ? this.state.modelosquero.filter(m => m.Value === this.state.modeloquero).map(m1 => { return { value: m1.Value, label: m1.Label } }) : ''}
                      className="my-1 select2 "
                      isClearable={true}
                      placeholder={"Selecione..."}
                      options={this.state.modelosquero.map(it => { return { value: it.Value, label: it.Label } })}
                      onChange={modelo => this.getVersaoQuero(modelo !== null ? modelo.value : 0)}
                    />
                    <label htmlFor="idmarca">Versão</label>
                    <Select
                      value={this.state.codfipe2 !== 0 ? this.state.versoesquero.filter(m => m.Value === this.state.codfipe2).map(m1 => { return { value: m1.Value, label: m1.Label.substring(0, 4) } }) : ''}
                      className=" my-1 select2"
                      placeholder={"Selecione..."}
                      options={this.state.versoesquero.map(it => { return { value: it.Value, label: it.Label.substring(0, 4) } })}
                      onChange={versao => this.setState({ codfipe2: versao.value })}
                    />
                    <label htmlFor="idmarca">Câmbio</label>
                    <Select
                      value={this.state.flautomatico ? { label: 'Automático', value: true } : { label: 'Manual', value: false }}
                      className=" my-1 select2"
                      placeholder={"Selecione..."}
                      options={[
                        { value: true, label: 'Automático' },
                        { value: false, label: 'Manual' }
                      ]}
                      onChange={cambio => this.setState({ flautomatico: cambio.value })}
                    />
                    <label htmlFor="valor" >Valor Aproximado R$</label>
                    <IntlCurrencyInput
                      value={this.state.valor}
                      className="form-control mx-1 w-100"
                      onChange={(e, v, mask) => this.setState({ valor: mask })}
                      currency="BRL" config={{
                        locale: "pt-BR",
                        formats: {
                          number: {
                            BRL: {
                              currency: "BRL",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            },
                          },
                        }
                      }}
                    />
                    {/*<CurrencyInput value={this.state.valor} className={"form-control"} decimalSeparator="," thousandSeparator="." onChangeEvent={vl => this.setState({ valor: vl.target.value })} />*/}
                    <label htmlFor="valor">Observações</label>
                    <textarea name="observacao" value={this.state.observacao} className="form-control" cols="30" onChangeCapture={ob => this.setState({ observacao: ob.target.value })} rows="3"></textarea>
                  </form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => this.fecharModal()}>
                    Fechar
                    </Button>
                  <Button variant="primary" onClick={() => this.queroComprar()}>
                    Enviar
                    </Button>
                </Modal.Footer>
              </Modal>

              <Modal show={this.state.loja} onHide={this.fecharLoja}>
                <Modal.Header closeButton>
                  <Modal.Title>Conheça nosso Aplicativo</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                  <h2>Deseja conheçer nosso aplicativo ?</h2>
                  <h4>Disponível na AppStore e GooglePlay</h4>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => this.fecharLoja()}>
                    Agora não
                    </Button>
                  <a href={this.state.linkloja} target={'_blank'} className={"btn btn-primary text-white"} onClick={() => { }}>
                    Sim
                    </a>
                </Modal.Footer>
              </Modal>

            </>
          </div>
        </div>
        <BotaoSimular show={this.state.anuncios.length > 0 ? true : false} />

        <div>
          <img height="1" width="1"
            src="https://www.facebook.com/tr?id=1438661459663519?ev=PageView?noscript=1" />
        </div>
      
   



      </div>
    )
  }
}

export default Geral;
