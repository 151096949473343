import React, { Component } from 'react'
import './Formulario.css'
import axios from 'axios'
import fundo from '../../assets/images/fundo_2.png'
import { BASE_URL_PHP } from '../../config/api'
import { getVeiculo, getUser, removeVeiculo } from "../../config/local";
import CurrencyInput from 'react-currency-input';
import InputMask from 'react-input-mask'
import veiculo from "../../services/veiculo/veiculo";
import Load from "../../components/loading/load";
import IntlCurrencyInput from "react-intl-currency-input"
import Select from "react-select";
import { Redirect } from 'react-router-dom'

class Formulario extends React.Component {

    state = {

        nome: '',
        email: '',
        telefone_ddd: '',
        telefone_numero: '',
        mensagem: '',
        resultado: [],
        loading: false,
        id: 0,
        dados: {},
        redirectTo: null,
        anos: []
    }

    componentDidMount = async () => {

        window.scrollTo(0, 0)
    }
    componentWillUnmount() {
        removeVeiculo()
    }


    orcamento = () => {
        this.setState({ loading: true }, () => {
            const formulario = {
                nome: this.state.nome,
                telefone: this.state.telefone_ddd.toString().replace("(", "").replace(")", "") + this.state.telefone_numero,
                email: this.state.email,
                mensagem: this.state.mensagem,
                flmobile: false
            }


            var data = new Date();
            var dia = String(data.getDate()).padStart(2, '0');
            var mes = String(data.getMonth() + 1).padStart(2, '0');
            var ano = data.getFullYear();
            var dataAtual = ano + '-' + mes + '-' + dia;

            let steve2 = {
                nome: this.state.nome,
                mensagem: this.state.mensagem,
                data: dataAtual,
                email: this.state.email,
                telefone: this.state.telefone_ddd + this.state.telefone_numero,
                origem: "VENDETUDO"
            }

            axios.post('https://steve.dev.br/steve/steveApi/lead/salvarleads/', steve2)
                .then(res => { })
                .catch(err => {
                    this.setState({ loading: false })
                })



            axios.post(`${BASE_URL_PHP}/api/consorcio`, formulario)
                .then(async res => {
                    this.setState({ resultado: res.data.parcelas, id: res.data.id, loading: false, dados: res.data })
                    await alert("Obrigado por enviar sua solicitação em brve um de nossos profissionais entrarão em contato");
                    await this.setState({ loading: false })
                    await this.props.history.push("/")
                }
                )
                .catch(err => {
                    this.setState({ loading: false });
                    let erros = '\n';
                    const campos = Object.keys(err.response.data).map(it => {
                        return erros = erros + err.response.data[it] + ''
                    })
                    alert("Por favor, verifique se os campos: " + erros)

                })

        })

    }

    render() {
        if (this.state.redirectTo) {
            return (
                <Redirect to={this.state.redirectTo} />
            )
        }

        return (
            <>
                <img src={fundo} className={"imgBackground"} alt="" />
                <div className="formulario">
                    <Load loading={this.state.loading} />
                    <h1>Solicite um Orçamento</h1>
                    <h3>Nossa equipe entrará em contato com as melhores ofertas disponíveis</h3>
                    <div className="container">

                        <div className="row p-5 text-left">

                            <div className="col-md-6 my-2">
                                <label htmlFor="">Nome</label>
                                <input type="text" value={this.state.nome} className="form-control" onChange={nome => this.setState({ nome: nome.target.value })} placeholder="Seu nome" />
                            </div>
                            <div className="col-md-6 my-2">
                                <label htmlFor="">Email</label>
                                <input type="email" value={this.state.email} className="form-control" onChange={email => this.setState({ email: email.target.value })} placeholder="Email" />
                            </div>
                            <div className="col-md-2 my-2">
                                <label htmlFor="">DDD</label>
                                <InputMask placeholder="DDD" className="form-control" value={this.state.telefone_ddd} onChange={vl => this.setState({ telefone_ddd: vl.target.value })} mask={"(99)"} />
                                {/*<input type="text" className="form-control" onChange={ telefone => this.setState({ telefone_ddd: telefone.target.value })} placeholder="Telefone"/>*/}
                            </div>
                            <div className="col-md-4 my-2">
                                <label htmlFor="">Telefone</label>
                                <InputMask placeholder="Numero" className="form-control" value={this.state.telefone_numero} onChange={vl => this.setState({ telefone_numero: vl.target.value })} mask={"99999-9999"} />
                            </div>
                            <div className="col-md-6 my-2">
                                <label htmlFor="">Mensagem</label>
                                <input type="text" value={this.state.mensagem} className="form-control" onChange={mg => this.setState({ mensagem: mg.target.value })} placeholder="Digite uma mensagem" />
                            </div>

                            <div className="col-md-12 my-2 mx-auto d-flex flex-row justify-content-center">
                                <button onClick={() => this.orcamento()} className="btn btn-info shadow">ORÇAMENTO</button>
                            </div>
                        </div>

                    </div>
                    <div>
                        <img height="1" width="1"
                            src="https://www.facebook.com/tr?id=1438661459663519?ev=PageView?noscript=1" />
                    </div>
                </div>
            </>
        )
    }


}

export default Formulario;
