import React from 'react'
import './load.css'
import ReactDOM from "react-dom";
import { RotateSpinner } from "react-spinners-kit";

class Load extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const loading = this.props.loading ? 
        <div className="loading" >
            <div className="loadingContent shadow-lg">
                <RotateSpinner size={45} color="#009FA7" loading={true} />
            </div>

        </div> : '';
        return (
            loading
        )
        return 
    }
}

export default Load;
