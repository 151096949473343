import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Login from '../../screens/autenticacao/login/Login'
import Cadastro from '../../screens/autenticacao/cadastro/Cadastro'
import Geral from '../../screens/anuncio/geral/Geral'
import Detalhe from '../../screens/anuncio/detalhe/Detalhe'
import Resumo from '../../screens/atividade/resumo/Resumo'
import Chat from '../../screens/chat/Chat'
import Conversa from '../../screens/chat/conversa/Conversa'
import CadastroAnuncio from '../../screens/anuncio/cadastro/Cadastro'
import EditarAnuncio from '../../screens/anuncio/editar/Editar'
import SimuladorPessoa from '../../screens/simulador/pessoa/Pessoa'
import SimuladorFormulario from '../../screens/simulador/formulario/Formulario'
import FipeConsulta from '../../screens/fipe/consulta/Consulta'
import Privacidade from "../../screens/politicas/privacidade/Privacidade";
import Perfil from "../../screens/autenticacao/perfil/Perfil";
import FaleConosco from "../../screens/contato/FaleConosco";
import Favoritos from "../../screens/autenticacao/favoritos/Favoritos";
import Recuperar from "../../screens/autenticacao/recuperarsenha/Recuperar";
import ScrollToTop from "../scrolltotop/ScrollToTop";
import Relatorio from "../../screens/autenticacao/relatorio/Relatorio";
import QueroComprar from "../../screens/autenticacao/querocomprar/QueroComprar";
import LojasApps from "../../components/lojas_apps/lojas_apps"
import consorcio from '../../screens/consorcio/Formulario'

function Main(){

  return(
    <main>
      <ScrollToTop>
        <Switch>
          <Route  path='/login' component={Login}/>
          <Route path='/cadastro' component={Cadastro}/>
          <Route path="/redefinirsenha/:token" component={Recuperar}/>
          <Route path='/perfil' component={Perfil}/>
          <Route exact path='/' component={Geral}/>
          <Route path="/anuncio/detalhe/:id" component={Detalhe}/>
          <Route path="/atividade/resumo" component={Resumo} />
          <Route path="/chat" component={Chat} />
          <Route path="/conversa" component={Conversa} />
          <Route path="/anuncio/cadastro" component={CadastroAnuncio} />
          <Route path="/anuncio/editar/:id" component={EditarAnuncio} />
          <Route path="/simular/pessoa" component={SimuladorPessoa} />
          <Route path="/simular/formulario" component={SimuladorFormulario} />
          <Route path="/fipe/consulta" component={FipeConsulta} />
          <Route path="/privacidade" component={Privacidade} />
          <Route path="/faleconosco" component={FaleConosco} />
          <Route path="/favoritos" component={Favoritos} />
          <Route path="/relatorio" component={Relatorio} />
          <Route path="/querocomprar" component={QueroComprar} />
          <Route path="/lojasapps" component={LojasApps} />
          <Route path="/consorcio" component={consorcio} />
        </Switch>
      </ScrollToTop>
    </main>  
  )
}

export default Main;