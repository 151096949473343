import React from "react";
import "./Perfil.css"
import axios from "axios"
import {BASE_URL_GESTOR, BASE_URL_PHP} from "../../../config/api";
import {getToken, getUser, setUser} from "../../../config/local";
import Load from "../../../components/loading/load";
import InputMask from "react-input-mask";
import CurrencyInput from "react-currency-input";
import Select from "react-select";
import IntlCurrencyInput from "react-intl-currency-input";

class Perfil extends React.Component{

    state = {
        id: 0,
        nome: "",
        email: "",
        id_cidade: 0,
        cidade: "",
        estado: "",
        ddd:"",
        numero: "",
        cpf_cnpj: "",
        perc_fipe: '',
        id_tipo: 0,
        idorigem: 0,
        idestado: 1,
        anuncios: 7,
        conversas: 10,
        edit: false,
        loading: false,
        estados: [],
        cidades: [],
        telefone: "",
        token: "",
        pessoaJuri: false,
        tipoPessoa: 'fisica',
        editpass: false,
        senha: '12345678',
        confsenha: '',
        beforeUser: {}
    }

    editar = () =>{
        if(!this.state.edit){
            return this.setState({ edit: true })
        }
        if(this.state.edit){
            this.setState({ loading: true })
            let beforeUser = this.state.beforeUser;
            const telefoneNumero = this.state.telefone
                .replace("_","")
                .replace("-","")
                .replace("(","")
                .replace(")","")
                .split('');
            let ddd ="";
            ddd = `${telefoneNumero[0]}${telefoneNumero[1]}`;
            let telefone = "";
            telefoneNumero.map((n,ind)=>{
                if(ind > 1) telefone += n
            })

            let user = {
                id: this.state.id,
                nome: this.state.nome,
                email: this.state.email,
                idcidade: parseInt(this.state.id_cidade),
                cpf_cnpj: this.state.cpf_cnpj,
                id_tipo: this.state.id_tipo,
                idorigem: this.state.idorigem,
                ddd: ddd,
                telefone: telefone
            }
            if(this.state.editpass){
                user.senha = this.state.senha
            }
            this.state.pessoaJuri ? user.perc_fipe = this.state.perc_fipe.toString().replace(".","").replace(",",".") : user.perc_fipe = '';
            const config = {
                headers: { Authorization: `${this.state.token}` }
            };
            axios.put(`${BASE_URL_PHP}/api/usuarios`, user, config)
                .then(res => {
                    this.setState({ loading: false })
                    alert('Alteração realizada com sucesso.')
                    beforeUser.email = this.state.email;
                    beforeUser.nome = this.state.nome;
                    beforeUser.perc_fipe = user.perc_fipe
                    setUser(beforeUser);
                    this.setState({ edit: false})
                    window.location.href = "/perfil"
                }
                )
                .catch(err => {
                    this.setState({ loading: false })
                    let erros = '\n';
                    const campos = Object.keys(err.response.data).map(it => {
                        return erros = erros + '- ' + err.response.data[it] + '\n'
                    })
                    alert("Não foi possível editar o usuário. Por favor, verifique: " + erros)
                } )

        }

    }

    cancelar = () =>{
        this.setState({ edit: false })
    }

    getUsuario = async () =>{
        await this.setState({
            loading: true
        })
        await this.setState({ beforeUser: getUser() })
        const token = await getToken()
        const config = await {
            headers: { Authorization: `${token}` }
        };
        await axios.get(`${BASE_URL_PHP}/api/usuarios/autenticado`,config)
            .then(res => {
                this.setState({ estado: res.data.cidade.estado,
                    nome: res.data.nome,
                    email: res.data.email,
                    ddd: res.data.telefone.ddd || '',
                    numero: res.data.telefone.telefone || '',
                    telefone: `${res.data.telefone.ddd || ''}${res.data.telefone.telefone || ''}`,
                    id_cidade: res.data.cidade.id,
                    idorigem: res.data.id_origem,
                    cpf_cnpj: res.data.cpf_cnpj,
                    pessoaJuri: res.data.cpf_cnpj.length > 14 ? true : false,
                    tipoPessoa: res.data.cpf_cnpj.length > 14 ? 'juridica' : 'fisica',
                    id_tipo: res.data.id_tipo,
                    perc_fipe: parseFloat(res.data.perc_fipe).toFixed(2).toString().replace('.',',') || '',
                    token: token }, () =>{})
                this.getCidades(res.data.cidade.estado)
            })
            .catch(err  => console.log(err))
        await this.setState({
            loading: false
        })
    }
    getEstados = () =>{
        this.setState({ loading: true })
        axios.get(`${BASE_URL_GESTOR}/api/estados/`)
            .then(res =>{
                this.setState({ estados : res.data, loading: false})
            } )
            .catch(err => {
                alert("Não foi possível carregar a lista de estados.Tente novamente mais tarde")
                this.setState({ loading: false})
            })
    }

    getCidades = (idestado) =>{
        this.setState({ loading: true, estado: idestado })
        axios.get(`${BASE_URL_GESTOR}/api/estados/${idestado}/cidades/`)
            .then(res => this.setState({ cidades : res.data , loading: false}))
            .catch(err => {
                alert("Não foi possível carregar a lista de cidades.")
                this.setState({ loading: false})
            })
    }


    componentDidMount = () =>{
        this.getEstados()
        this.getUsuario()
    }

    mudarTipo = tipo =>{
        this.setState({ tipoPessoa: tipo, cpf_cnpj: '', pessoaJuri: tipo === 'fisica' ? false: true, id_tipo: tipo === 'fisica' ? 1 : 2 })
    }

    editarSenha = (senha) =>{
        if(!this.state.editpass){
            this.setState({ editpass: true, senha: '' })
        }else{
            this.setState({ senha: senha })
        }
    }

    render() {
        return(
            <div className={"perfil"}>
                <div id={"perfil"}>
                    <Load loading={this.state.loading} />
                    <div className={"perfilContent container shadow"}>
                        <div>
                            <div className={"perfilHeader"}>
                                <h1>Dados do Perfil</h1>
                                <div>
                                    <button onClick={() => this.editar()} className={ this.state.edit ? " btnEditar bgInfo" : "btnEditar bgWarning" }>{this.state.edit ? "Salvar" : "Editar"}</button>
                                    <button onClick={() => this.cancelar()} className={ this.state.edit ? " btnEditar bg-danger ml-0 my-1 ml-md-2 " : "btnEditar bg-danger ml-2 d-none" }>Cancelar</button>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-12 col-md-6"}>
                                    <label htmlFor="">Nome:</label> <br/>
                                    <input tabIndex={5} type="text" autoFocus={true} value={this.state.nome} disabled={!this.state.edit}  onChange={v => this.setState({ nome: v.target.value})} className={"inputPerfil"}/>
                                </div>
                                <div className={"col-12 col-md-6"}>
                                    <label htmlFor="">Email:</label> <br/>
                                    <input type="text" value={this.state.email} disabled={!this.state.edit} onChange={v => this.setState({ email: v.target.value})} className={"inputPerfil"}/>
                                </div>
                                <div className={"col-12 col-md-6"}>
                                    <label htmlFor="">Telefone:</label> <br/>
                                    <InputMask value={this.state.telefone} disabled={!this.state.edit} placeholder="Telefone"  mask={ "(99)99999-9999" } onChange={tel => this.setState({ telefone: tel.target.value})} className={"inputPerfil"} />
                                </div>
                                <div className={"col-12 col-md-3"}>
                                    <label htmlFor="">Estado:</label> <br/>
                                    <Select
                                        value={this.state.estado !== 0 ? this.state.estados.filter(estado => estado.id === this.state.estado ).map(m1 => { return { value: m1.id, label: m1.nomeEstado  } }) : ''}
                                        className={`my-1 select2 ${!this.state.edit ? 'disable' : ''}`}
                                        placeholder={"Selecione..."}
                                        options={this.state.estados.map(it => { return { value: it.id, label: it.nomeEstado }})}
                                        onChange={estado => this.getCidades(estado.value)}
                                        disabled={!this.state.edit}
                                    />
                                </div>
                                <div className={"col-12 col-md-3"}>
                                    <label htmlFor="">Cidade:</label> <br/>
                                    <Select
                                        value={this.state.id_cidade !== 0 ? this.state.cidades.filter(cidade => cidade.id === this.state.id_cidade ).map(m1 => { return { value: m1.id, label: m1.nomeCidade  } }) : ''}
                                        className={`my-1 select2 ${!this.state.edit ? 'disable' : ''}`}
                                        placeholder={"Selecione..."}
                                        options={this.state.cidades.map(it => { return { value: it.id, label: it.nomeCidade }})}
                                        onChange={cidade => this.setState({ id_cidade: cidade.value} )}
                                        disabled={!this.state.edit}
                                    />
                                </div>
                                <div className={"col-12 col-md-6"}>
                                    <label htmlFor="">Alterar Senha</label>
                                    <input type={this.state.mostrar ? 'text': 'password'} value={this.state.senha} disabled={!this.state.edit} onChange={v => this.editarSenha(v.target.value) }  className={"inputPerfil"}/>
                                    {
                                        this.state.editpass ?
                                            <button className={"eye"} type={'button'} onClick={() => this.setState({ mostrar: !this.state.mostrar })}>  <i className={ !this.state.mostrar ? 'fas fa-eye' : 'fas fa-eye-slash'}></i> </button>
                                            : ''
                                    }

                                </div>
                            </div>

                            <div className={" col-12 col-md-4"}>
                                <label htmlFor="" >Tipo de Usuário</label> <br/>
                                <input type="radio" disabled={!this.state.edit} className={"mr-2"} value={"fisica"} onChange={() => this.mudarTipo('fisica')} checked={this.state.tipoPessoa === 'fisica'} name={"tipo"} />
                                <label htmlFor="" className={"mr-2"}>Fisica</label> <br/>
                                <input type="radio" disabled={!this.state.edit} className={"mr-2"} value={"juridica"} onChange={() =>this.mudarTipo('juridica')} checked={this.state.tipoPessoa === 'juridica'} name={"tipo"} />
                                <label htmlFor="" className={"mr-2"}>Juridica</label>
                            </div>
                            <div className={"col-12 col-md-6"}>
                                <InputMask placeholder={this.state.tipoPessoa === 'fisica' ? 'CPF': 'CNPJ'} disabled={!this.state.edit} className={"inputPerfil"}   onChange={ vl => this.setState({ cpf_cnpj: vl.target.value }) } mask={ this.state.pessoaJuri ? "99.999.999/9999-99" : "999.999.999-99" } value={this.state.cpf_cnpj} />
                            </div>
                            {this.state.tipoPessoa === 'juridica' ?
                                <div className={"col-12 col-md-6"}>
                                    <label htmlFor="">Porcentagem tabela Fipe %</label>
                                    <IntlCurrencyInput
                                        value={this.state.perc_fipe}
                                        disabled={!this.state.edit}
                                        className="form-control"
                                        onChange={(e,v,mask) => this.setState({ perc_fipe: mask  })}
                                        currency="BRL" config={{
                                        locale: "pt-BR",
                                        formats: {
                                            number: {
                                                BRL: {
                                                    currency: "BRL",
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                },
                                            },
                                        }
                                    }}
                                    />

                                </div>
                                : ''}
                        </div>
                    </div>
                </div>
                <div>
          <img height="1" width="1"
            src="https://www.facebook.com/tr?id=1438661459663519?ev=PageView?noscript=1" />
        </div>
            </div>

        )
    }


}

export  default Perfil
