import React from 'react';
import './Recuperar.css';
import logo from '../../../assets/logos/logos vende tudo/200 x 84/LOGO VENDE TUDO.png'
import { Link, withRouter, useParams } from 'react-router-dom'
import axios from 'axios'
import { BASE_URL_DJANGO , BASE_URL_PHP } from '../../../config/api'
import { setUser , getUser , getToken } from '../../../config/local'
import Load from '../../../components/loading/load';

class recuperar extends React.Component {


    state = {
        email : '',
        senha: '',
        loading: false,
        recuperar: false,
        border: false,
        token: '',
        confsenha: '',
        mostrar: false
    }

    componentDidMount =async ()=> {
        const { match: { params } } = await this.props;
        await this.setState({ token: params.token, recuperar: params.token != 0 ? true : false })
    }

    enviarEmail = ()=>{
        this.setState({ loading: true })
        axios.post(`${BASE_URL_PHP}/api/usuarios/recuperarsenha`,{
            email: this.state.email
        }).then(res => {
            alert('Verifique seu email acesse o link que o enviamos.')
            this.setState({ loading: false })
            window.scrollTo(0,0)
            this.props.history.push("/login")
        }).catch(err => {
            alert('Não foi possível enviar o email. Por favor tente novamente mais tarde.')
            this.setState({  loading: false })
            window.scrollTo(0,0)
        })
    }

    criarNova = () =>{
        this.setState({ loading: true })
        axios.patch(`${BASE_URL_PHP}/api/usuarios/recuperarsenha`,{
            token: this.state.token,
            senha: this.state.senha
        }).then(res => {
            alert('Senha alterada com sucesso.')
            this.setState({ recuperar: false, loading: false })
            this.props.history.push("/login")
        }).catch(err => {
            alert('Não foi possível alterar a senha. Por favor tente novamente mais tarde.')
            this.setState({ recuperar: true , loading: false })
        })
    }

    render(){
        return (
            <div className="recuperar">
                <div className="recuperarContent p-5 mx-1 text-center shadow border">
                    <Load loading={this.state.loading} />
                    <a href="/"><img src={logo} className="img-fluid" width="200"/></a>
                    <div>
                        <h4 className="my-3">{!this.state.recuperar ? 'Insira seu email de recuperação.' : 'Escolha sua nova senha.'}  </h4>
                        { this.email }
                        <form>
                            {!this.state.recuperar ?
                                <>
                                    <input placeholder="E-mail" type="email" onChange={ (email) => {  this.setState({ email: email.target.value  }) } }/>
                                    <a onClick={this.enviarEmail} className="btn btn-warning my-3"><b>RECUPERAR</b></a>
                                    <div className="recuperarConta">
                                        <Link to="/">Voltar ao início</Link>
                                    </div>
                                </> :
                                <>
                                    <input value={this.state.senha} placeholder="Nova senha" type={this.state.mostrar ? 'text': 'password'} onChange={ (senha) => {  this.setState({ senha: senha.target.value  }) } }/>

                                    <button className={"eyeRecuperar"} type={'button'} onClick={() => this.setState({ mostrar: !this.state.mostrar })}>  <i className={ !this.state.mostrar ? 'fas fa-eye' : 'fas fa-eye-slash'}></i> </button>
                                    <a onClick={this.criarNova} className="btn btn-warning my-3"><b>CONFIRMAR</b></a>
                                </>

                            }

                        </form>
                    </div>

                </div>
                <div>
          <img height="1" width="1"
            src="https://www.facebook.com/tr?id=1438661459663519?ev=PageView?noscript=1" />
        </div>
            </div>
        );
    }

}


export default withRouter(recuperar);
