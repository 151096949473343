import React from 'react';
import './Card.css';
import fotos from '../../assets/images/fotos.png'
import vendetudo from '../../assets/logos/logos vende tudo/200 x 84/LOGO VENDE TUDO.png'
import { withRouter } from 'react-router-dom'
import { setVeiculo } from "../../config/local";

class Card extends React.Component {
  
    viewCar = async anuncio  =>{
        // await console.log(anuncio)
        // await setVeiculo(anuncio)

        console.log(anuncio)

        await this.props.history.push(`/anuncio/detalhe/${anuncio.id}/${anuncio.infoCar.Modelo}`)
    }
    numberToReal = (numero) => {
        var numero = numero.toFixed(2).split('.');
        numero[0] =  numero[0].split(/(?=(?:...)*$)/).join('.');
        return numero.join(',');
    }

    convertKm = (km) =>{
        if(km !== undefined){
            return km.toLocaleString('pt-br', {minimumFractionDigits: 0})
        }
    }

    render(){
      return (
        <button onClick={ () => this.viewCar(this.props.dados )} className="cardAnun shadow-sm">
          <div className="cardHeader">
            <h5>{this.props.dados.infoCar.Modelo} - {this.props.dados.infoCar.AnoModelo}</h5>
          </div>
          <div className="cardBody">
            <div className="cardImgDiv">
               <div className="imgCard">
                   <img src={this.props.dados.image_anuncio.length > 0 ? this.props.dados.image_anuncio[0].foto : vendetudo} alt="new" className={"imgCardFoto"} />
               </div>

              <img src={fotos} className="cardImgFoto"/>
              <span>{this.props.dados.image_anuncio.length}</span>
            </div>
            <div className="p-3">
              <h6><b>R$ { this.numberToReal( parseFloat(this.props.dados.valor))}</b></h6>
              <p>
              {this.props.dados.ano}/{this.props.dados.infoCar.AnoModelo} <br/>
              {this.props.dados.cor.nome} <br/>
                { this.convertKm(this.props.dados.km) } km <br/>
                {this.props.dados.caracteristica.nome}
                  <span>{this.props.dados.cidade || ''}</span>
              </p>

            </div>
          </div>
        </button>
      );
    }

}

export default withRouter(Card);