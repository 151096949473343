import React from 'react'
import './Detalhe.css'
import pin from '../../../assets/images/pin.png'
import share from '../../../assets/images/icon_2.png'
import like from '../../../assets/images/icon_1.png'
import logo from '../../../assets/logos/logos vende tudo/200 x 84/LOGO VENDE TUDO.png'
import check from '../../../assets/images/item.png'
import axios from 'axios'
import { BASE_URL_DJANGO, BASE_URL_PHP } from '../../../config/api'
import { getVeiculo, getUser, setVeiculo } from "../../../config/local";
import { withRouter, useParams } from 'react-router-dom'
import BotaoSimular from "../../../components/botaosimular/BotaoSimular";
import Compartilhar from "../../../components/compartilhar/Compartilhar"
import Load from "../../../components/loading/load"
import InputMask from "react-input-mask";
import vendetudo from '../../../assets/logos/logos vende tudo/200 x 84/LOGO VENDE TUDO.png'

class Detalhe extends React.Component {


    state = {
        infoCar: {},
        anuncio: {},
        anunciante: {},
        caracteristicas: [],
        imagens: [],
        meses: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro"
        ],
        dias: ["domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        cor: {},
        imgIndex: 0,
        logged: false,
        user: {},
        telefone: '',
        title: '',
        mensagem: '',
        compartilhar: false,
        link: 'https://sharevendetudo.herokuapp.com/',
        // link: 'https://www.vtudo.com.br/anuncio/detalhe/',
        loading: false,
        categorianome: ''
    }

    componentDidMount = async () => {
        const { match: { params } } = await this.props;
        const user = await getUser();

        if (user) {
            await this.setState({ logged: true, user: user })
            if (user.telefone) {
                this.setState({ telefone: `${user.telefone.ddd}${user.telefone.numero}` })
            }
        } else {
            await this.setState({ logged: false })
        }
        await this.getVeiculo(params.id)

    }
    enviarMensagem = () => {

        const mensagem = {
            "mensagem": this.state.mensagem,
            "pessoa_id": this.state.logged ? this.state.user.id : null,
            "receiver_id": this.state.anuncio.user,
            "information": !this.state.logged ? {
                "nome": this.state.user.nome,
                "email": this.state.user.email,
                "telefone": this.state.telefone.replace('(', '').replace(')', '').replace('-', '').replace('_', '')
            } : null,
            "anuncio": this.state.anuncio.id
        }
        const valid = this.state.mensagem.trim() !== ""
            && this.state.user.nome
            && this.state.user.email
            && this.state.telefone


        if (!valid) {
            return alert('Por favor, preencha todos os campos')
        }

        axios.post(`${BASE_URL_DJANGO}/anuncio/chat/`, mensagem)
            .then(res => {
                if (!this.state.logged) {
                    const user = {
                        nome: '',
                        email: ''
                    };
                    this.setState({ mensagem: '', telefone: '' })
                    this.setState({ user }, () => {
                        alert('O anunciante entrará em contato por e-mail ou telefone.')
                    })
                } else {
                    this.setState({ mensagem: '' }, () => {
                        alert('Sua mensagem foi enviada com sucesso. Verifique a conversa na sua aba de chats.')
                    })
                }
            })
            .catch(err => {
                alert('Não foi possível enviar a mensagem. Tente novamente mais tarde.')

            })
    }

    numberToReal = (numero) => {
        if (isNaN(numero)) {
            return ''
        }
        var numero = numero.toFixed(2).split('.');
        numero[0] = numero[0].split(/(?=(?:...)*$)/).join('.');
        return numero.join(',');
    }
    getVeiculo = (id) => {
        this.setState({ loading: true })
        axios.get(`${BASE_URL_DJANGO}/anuncios/${id}/`)
            .then(res => {
                this.setState({ anuncio: res.data })
                this.setState({ infoCar: res.data.infoCar }, () => {
                    this.montarMetas()
                })
                this.setState({ cor: res.data.cor })
                this.setState({ anunciante: res.data.user_info })
                this.setState({ caracteristicas: res.data.caracteristica })
                this.setState({ imagens: res.data.image_anuncio.map(img => img.foto) })
                this.setState({ loading: false })
                setVeiculo(res.data)

                console.log(res.data.tipo_veiculo)
                if (res.data.tipo_veiculo == 1) {
                    this.setState({categorianome : 'Carros'})
                }
                else {
                    if (res.data.tipo_veiculo == 2) {
         
                        this.setState({categorianome : 'Motos'})
                    }else{

                        this.setState({categorianome : 'Caminhões e micro-ônibus'})
                    }
                }


            })
            .catch(err => {
                this.setState({ loading: false })
                alert('Não foi possível encontrar os dados do anuncio.')
                this.props.history.push("/")
            })
    }
    convertKm = (km) => {
        if (km !== undefined) {
            return km.toLocaleString('pt-br', { minimumFractionDigits: 0 })
        }
    }
    favoritar = (id) => {
        this.setState({ loading: true })
        axios.post(`${BASE_URL_DJANGO}/favoritos/`, {
            anuncio: id,
            user: this.state.user.id
        }).then(res => {
            alert('Anúncio salvo na lista de favoritos.')
            this.setState({ loading: false })
        }).catch(err => {
            alert('Não foi possível realizar a operação. Verifique se o anuncio ja esta na sua lista ou tente novamente mais tarde.');
            this.setState({ loading: false })
        })
    }

    montarMetas = () => {
        if (this.state.anuncio !== null) {

            const modelo = this.state.infoCar.Modelo
            const marca = this.state.infoCar.Marca
            const ano = this.state.infoCar.AnoModelo
            const combustivel = this.state.infoCar.Combustivel;
            const desc = `${modelo} - ${marca} - ${ano} - ${combustivel}`

            document.querySelector('title').innerText = `${modelo}`
            document.querySelector('meta[name="description"]').setAttribute("content", desc);
        }

    }
    formatarDescricao = desc => {
        const descricao = `${desc}`
        return descricao.split("\n").map((item, idx) => <span key={idx}>{item}<br /></span>)
    }

    render() {

        return (
            <div className="detalhe container">
                <Load loading={this.state.loading} />
                <div className="d-flex justify-content-start text-left flex-wrap">
                    {this.state.anuncio !== null ?
                        <div className="detalheDados">
                            <h3>{this.state.infoCar.Modelo}</h3>
                            <div className="d-flex">
                                {this.state.imagens ?
                                    <div className="detalheImagem">
                                        {/*{this.state.imgIndex !== 0 ?*/}
                                        {/*    <button onClick={() =>{*/}
                                        {/*        if( this.state.imgIndex - 1 >= 0 ){*/}
                                        {/*            this.setState({ imgIndex: this.state.imgIndex - 1})*/}
                                        {/*        }*/}
                                        {/*    }*/}
                                        {/*    } className={"btnArrowLeft"}> <i className={"fas fa-chevron-left"}></i> </button> : ''*/}
                                        {/*}*/}
                                        <img src={this.state.imagens[this.state.imgIndex] || vendetudo} />
                                        {/*{this.state.imgIndex !== this.state.imagens.length - 1 ?*/}
                                        {/*    <button onClick={() =>{*/}
                                        {/*        let size = this.state.imagens.length*/}
                                        {/*        let next = this.state.imgIndex + 1;*/}
                                        {/*       if(size > next ){*/}
                                        {/*           this.setState({ imgIndex: next })*/}
                                        {/*       }*/}
                                        {/*    }*/}
                                        {/*    } className={"btnArrowRight"}> <i className={"ml-1 fas fa-chevron-right"}></i> </button> : <span></span> }*/}
                                    </div> : {}
                                }
                                <div className={"arrowsContent"}>
                                    {this.state.imgIndex !== 0 ?
                                        <button onClick={() => {
                                            if (this.state.imgIndex - 1 >= 0) {
                                                this.setState({ imgIndex: this.state.imgIndex - 1 })
                                            }
                                            if (this.state.imgIndex < 5) {
                                                let div = document.getElementById('scroolImgs')
                                                div.scrollTop -= 50
                                            } else {
                                                let div = document.getElementById('scroolImgs')
                                                div.scrollTop += 50
                                            }
                                        }}><i className={"fas fa-chevron-left"}></i></button> : <div></div>}
                                    {this.state.imgIndex !== this.state.imagens.length - 1 ?
                                        <button onClick={() => {
                                            let size = this.state.imagens.length
                                            let next = this.state.imgIndex + 1;
                                            if (size > next) {
                                                this.setState({ imgIndex: next })
                                            }
                                            if (this.state.imgIndex < 5) {
                                                let div = document.getElementById('scroolImgs')
                                                div.scrollTop -= 50
                                            } else {
                                                let div = document.getElementById('scroolImgs')
                                                div.scrollTop += 50
                                            }


                                        }}><i className={"fas fa-chevron-right"}></i></button> : <div></div>}
                                </div>
                                <div className={"d-none d-md-block detalhesImagensSmallScroll"} id={"scroolImgs"}>
                                    {this.state.imagens ?
                                        this.state.imagens.map((img, ind) =>
                                            <div className={this.state.imgIndex === ind ? "detalheImageSmall" : "detalheImageSmall opacity"} key={ind}>
                                                <img onClick={() => {
                                                    if (ind < 5) {
                                                        this.setState({ imgIndex: ind });
                                                        let div = document.getElementById('scroolImgs')
                                                        div.scrollTop -= 50
                                                    } else {
                                                        this.setState({ imgIndex: ind });
                                                        let div = document.getElementById('scroolImgs')
                                                        div.scrollTop += 50
                                                    }

                                                }
                                                }
                                                    src={img} />
                                            </div>
                                        ) : {}
                                    }
                                </div>
                            </div>
                            <div className="detalhePreco">
                                <span>R$ {this.numberToReal(parseFloat(this.state.anuncio.valor))}</span>
                            </div>
                            <div className="py-2">
                                <b>Publicado em {this.state.infoCar.DataConsulta} </b> <br />
                                <b>Categoria: </b>{this.state.categorianome} <br />
                                <b>Marca: </b>{this.state.infoCar.Marca} <br />
                                <b>Modelo: </b>{this.state.infoCar.Modelo} <br />
                                <b>Ano: </b>{this.state.anuncio.ano}/{this.state.infoCar.AnoModelo} <br />
                                <b>KM: </b>{this.convertKm(this.state.anuncio.km)}km <br />
                                <b>Cor: </b> {this.state.cor.nome} <br />
                                <b>Tipo Combus.: </b>{this.state.anuncio.combustivel ? this.state.anuncio.combustivel.nome : ''} <br />

                                <b>Descrição:</b>
                                <p>{this.formatarDescricao(this.state.anuncio.observacao)}</p>
                                <div className="text-left">
                                    <b>Opcionais: </b> <br />
                                    <div className="py-4 row">
                                        {this.state.caracteristicas.map((op, idx) =>
                                            <div key={idx} className="mx-2 col-md-5"> <img src={check} className="mr-2" width="30" /> {op.nome} </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="detalheIcons">
                                <b>Localização: </b>
                                <div><img src={pin} /> {this.state.anuncio.cidade} </div>
                                <b>Anunciante: </b>{this.state.anunciante.name} <br />
                                <div>
                                    <span> {this.state.logged ? <a onClick={() => this.favoritar(this.state.anuncio.id)}><img src={like} alt={"Favoritar"} /></a> : ''} <img onClick={() => this.setState({ compartilhar: true })} src={share} alt={"Compartilhar"} /> </span>
                                </div>
                            </div>

                        </div> : {}
                    }

                    <div className="detalheSimulador">
                        <div>
                            <img src={logo} className="img-fluid" />
                            <h4 className={"my-2"}>Fale com o anunciante</h4>
                            <form>
                                <input type="text" className="form-control my-1" placeholder="SEU NOME"
                                    value={this.state.user.nome} onChange={value => {
                                        let user = this.state.user
                                        user.nome = value.target.value
                                        this.setState({ user: user })
                                    }} />
                                <InputMask placeholder="Telefone" value={this.state.telefone} mask={"(99)99999-9999"} className="form-control my-1" onChange={tel => this.setState({ telefone: tel.target.value })} />
                                <input type="text" className="form-control my-1" placeholder="E-MAIL"
                                    value={this.state.user.email} onChange={value => {
                                        let user = this.state.user
                                        user.email = value.target.value
                                        this.setState({ user: user })
                                    }} />
                                <textarea className="form-control my-1" rows="6" placeholder="ESCREVA SUA MENSAGEM"
                                    value={this.state.mensagem}
                                    onChange={msg => this.setState({ mensagem: msg.target.value })} />
                                <a onClick={() => this.enviarMensagem()}
                                    className="btn btn-warning btn-block my-2">{"ENVIAR MENSAGEM"}</a>
                            </form>
                        </div>
                    </div>
                </div>


                <BotaoSimular show={true} />
                <Compartilhar link={this.state.link + this.state.anuncio.id} id={this.state.anuncio.id} compartilhar={this.state.compartilhar} onClick={() => this.setState({ compartilhar: false })} />


                <div>
                    <img height="1" width="1"
                        src="https://www.facebook.com/tr?id=1438661459663519?ev=PageView?noscript=1" />
                </div>
            </div>


        )
    }


}

export default withRouter(Detalhe);
