import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import './Conversa.css'
import { BASE_URL_DJANGO } from "../../../config/api";
import Load from '../../../components/loading/load'
import { getUser } from "../../../config/local";

import axios from 'axios'

class Conversa extends React.Component {

    state = {
        mensagens: [],
        receivedId: 0,
        anuncioId: 0,
        anuncianteId: 0,
        userId: 0,
        loading: false,
        mensagem: '',
        anuncio: {}
    }

    getConversa = async () => {
    
        this.setState({ loading: true });
        const params = await window.location.search.substr(1);
        await this.setState({ anuncioId: this.findGetParameter('anuncioId'), anuncianteId: this.findGetParameter('idAnunciante') });
        let url = await `${BASE_URL_DJANGO}/chat/mensagens/`

        await this.state.userId !== this.findGetParameter('idAnunciante') ?
        url = `${url}outos-anuncios/${this.findGetParameter('anuncioId')}/${this.state.userId}/${this.findGetParameter('idAnunciante')}/` :
        url = `${url}meus-anuncios/${this.findGetParameter('anuncioId')}/${this.state.userId}/`

        await axios.get(`${url}`)
      
            .then(res => {
                
                this.setState({ mensagens: res.data.map(item => item) })
                this.setState({ receivedId: res.data[0].receiver_id })
                this.setState({ anuncio: res.data[0].anuncio })
                this.setState({ loading: false });
                document.getElementById("enviarmensagem").disabled  = false;
            }
            ).catch(err => console.log(err), this.setState({ loading: false }))
    }

    findGetParameter = (parameterName) => {
        var result = null,
            tmp = [];
        window.location.search
            .substr(1)
            .split("&")
            .forEach(function (item) {
                tmp = item.split("=");
                if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
            });
        return result;
    }
    buscaFiltros = () => {
        if (this.state.palavras.trim() === '') {
          return this.getAnuncios()
        }
        const filtro = {
          nome: 'search',
          valor: this.state.palavras
        }
        this.addFiltro(filtro);
      }

    enviarMensagem = () => {
       document.getElementById("enviarmensagem").disabled  = true;
        const mensagem = {
            "mensagem": this.state.mensagem,
            "pessoa_id": this.state.userId,
            "receiver_id": this.state.anuncianteId,
            "anuncio": this.state.anuncioId
        }
        if (this.state.mensagem.trim() === "") { 
            return alert('Favor digitar a mensagem que deseja enviar.');   
        }
        
        axios.post(`${BASE_URL_DJANGO}/anuncio/chat/`, mensagem)
            .then(res => {
                this.setState({ loading: true })
                this.setState({ mensagem: '' })
                this.getConversa()
                this.setState({ loading: false })
            })
            .catch(err =>
                alert('Não foi possível enviar a mensagem. Tente novamente mais tarde.'),
                this.setState({ loading: false })
            )
    }

    componentDidMount = async () => {
        this.setState({ loading: true })
        const user = await getUser()
        await user !== null ?
            await this.setState({ userId: user.id }) :
            await this.props.history.push("/")
       await this.getConversa()
    }

    getData = (data) => {
        const date = new Date(data);
        return `${date.getDate()}/${date.getUTCMonth() + 1}/${date.getFullYear()} as ${date.toISOString().substr(11, 8)}`
    }

    render() {
        return (
            <div className="geral">
                <Load loading={this.state.loading} />
              
                <div className="pt-4 mt-5 conversa" >
                    <h3 className="mt-5">Conversa do Anuncio - {this.state.anuncio.infoCar ? this.state.anuncio.infoCar.Modelo : ''}</h3>
                    <div className="conversas p-2">
                        {this.state.mensagens.map(item =>
                            <div className={item.receiver_id !== this.state.userId ? "p-2 bg-light my-2 text-right" : "p-2 bg-light my-2 text-left"}>
                                <div className=""> <b> {item.get_name_pessoa} -{item.get_datetime}</b></div>
                                <p>{item.mensagem}</p>
                            </div>
                        )}
                        <textarea value={this.state.mensagem} placeholder="Escreva sua mensagem" onChange={msg => this.setState({ mensagem: msg.target.value })} className="form-control mt-5 mb-2"></textarea>
                        <button className="btn btn-primary btn-block" id="enviarmensagem" onClick={() => this.enviarMensagem()} >Enviar Mensagem</button>
                       
                    </div>
                    <div>
                        <img height="1" width="1"
                            src="https://www.facebook.com/tr?id=1438661459663519?ev=PageView?noscript=1" />
                    </div>
                </div>
        
            </div>
        )
    }


}


export default withRouter(Conversa)

