import firebase from 'firebase';
import { setTokenFirebase } from "./local";

export const inicializarFirebase = () => {
    firebase.initializeApp({
        messagingSenderId: "324675382210",
        projectId: 'vende-tudo-6355b',
        apiKey: 'AIzaSyC0ThIhJTWEqQvJIe4bewFX0x9pOn9F7nU',
        appId: '1:324675382210:web:0348d9a22760ea433e845b'
    });
    pedirPermissaoParaReceberNotificacoes();
}

export const pedirPermissaoParaReceberNotificacoes = async () => {
    try {
        const messaging = firebase.messaging();
        await messaging.requestPermission();
        const token = await messaging.getToken();
        console.log('token do usuário:', token);
        await setTokenFirebase(token)
        return token;
    } catch (error) {
        console.error(error);
    }
}
