import React, {useState } from 'react'
import './Chat.css'
import mensagem from '../../assets/images/mensagem.png'
import axios from 'axios'
import { BASE_URL_PHP, BASE_URL_DJANGO } from '../../config/api'
import { Link, withRouter } from 'react-router-dom'
import { getUser } from "../../config/local";
import fundo from "../../assets/images/fundo_1.png";

class Chat extends React.Component{


  state ={
    listameusanuncios: [],
    listaoutrosanuncios : [],
    chats : [],
    outros: false,
    user: {}
  }

  getMensagens(){
    //axios.get(`${BASE_URL_DJANGO}/anuncio/chat/?pessoa_id=1`)
    axios.get(`${BASE_URL_DJANGO}/chat/lista/meus-anuncios/${this.state.user.id}/`)
    .then(res => this.setState({ listameusanuncios : res.data.data }))
        .then( () => {
          this.mudarLista(this.state.listameusanuncios)
        })
    axios.get(`${BASE_URL_DJANGO}/chat/lista/outros-anuncios/${this.state.user.id}/`)
        .then(res => this.setState({ listaoutrosanuncios: res.data.data }))
  }

  verMensagem = (id, idAnunciante = 0 ) =>{
    this.props.history.push(`/conversa/?anuncioId=${id}&idAnunciante=${idAnunciante}`)
  }

  mudarLista = async (lista) =>{
    if(lista === this.state.listaoutrosanuncios )
      await this.setState({ outros: true })
      await this.setState({ chats : lista })
  }

  componentDidMount = async () =>{
    const user = await getUser();
    await this.setState({ user: user })
    await this.getMensagens()
  }

  numberToReal = (numero) => {
    if(isNaN(numero)){
        return  ''
    }
    var numero = numero.toFixed(2).split('.');
    numero[0] = numero[0].split(/(?=(?:...)*$)/).join('.');
    return numero.join(',');
  }

  render(){
    return(
        <>
          <img src={fundo} className={"imgBackground"} alt=""/>
      <div className="chat">
        <div className="container">
          <h1 className="mt-2 mb-2 text-white">Suas Conversas</h1>
          <div className="py-2">
            <button className="btn btn-primary mr-2" onClick={() => this.mudarLista( this.state.listameusanuncios )}>Vendas</button>
            <button className="btn btn-warning" onClick={() => this.mudarLista( this.state.listaoutrosanuncios )}>Compras</button>
          </div>
          <div className="chatConteudo mx-auto">
           
            { this.state.chats.length === 0  ?
             
                <div>
                  <h4 className="mt-5 mb-2">Você não tem chats ativos</h4>
                  <img src={mensagem} className="pb-2"/>
                  <p>Assim que uma nova conversa for iniciada, ela aparecerá aqui</p>
                </div>
                :
                <div>
                  {this.state.chats.map(
                      item =>
                          <div onClick={() => this.verMensagem(item.Anuncio.id, item.Pessoa.id ) } className="mensagemCard my-2">
                            <b>
                              { item.Anuncio.infoCar.Modelo } - R$ {item.Anuncio.valor != null ? item.Anuncio.valor : item.Anuncio.infoCar.Valor}
                            </b> <br/>
                            <i>{ item.Pessoa ? item.Pessoa.nome : 'Usuário Tal'} - { item.ultima_msg ? item.ultima_msg.dtcreate : ''}</i> <br/>
                            <p className={"mensg"}>

                              { item.ultima_msg ? item.ultima_msg.mensagem  : "Lorem ipsum tal tal tal" }
                            </p>
                          </div>
                  )}
                </div>
            }
          </div>
        </div>
        <div>
          <img height="1" width="1"
            src="https://www.facebook.com/tr?id=1438661459663519?ev=PageView?noscript=1" />
        </div>
      </div>
          </>
    )
  }
}

export default withRouter(Chat);