import React from "react";
import FacebookLogin  from 'react-facebook-login'
import "./FacebookLogin.css"

class FaceLogin extends React.Component{

    responseFacebook=(res)=>{
        console.log(res)
    }

    render(){
        return(
            <FacebookLogin
                appId="541495163406856"
                autoLoad={false}
                fields="name,email,picture"
                callback={(res)=>this.responseFacebook(res)}
                icon="fa-facebook"
                cssClass="my-facebook-button-class btnFace"
                textButton={"Continue com Facebook"}
            />
        )
    }
}
export default FaceLogin
