import React from 'react';
import axios from 'axios'
import {BASE_URL_DJANGO, BASE_URL_PHP} from '../../config/api'
import Pagination from "react-pagination-library";
import "react-pagination-library/build/css/index.css";
import { Accordion, Button, Modal } from "react-bootstrap";
import {getUser, getToken, verificarDispositivo, setMobile, removeVeiculo} from "../../config/local";
import CurrencyInput from "react-currency-input";
import InfiniteScroll from 'react-infinite-scroll-component';
import Select from 'react-select'
import IntlCurrencyInput from "react-intl-currency-input";
import Drawer from "@material-ui/core/Drawer";
import { pedirPermissaoParaReceberNotificacoes } from '../../config/push-notifications';
import { Link, withRouter } from 'react-router-dom'
import {Linking } from 'react-native'; 

class LojasApps extends React.Component {
    state =  
    {    
        linkloja: ''
        
      }

      componentWillMount = () =>{
        this.verificarDispositivo()
      }


    verificarDispositivo = async () =>{

        let userAgent = await navigator.userAgent.toLowerCase();
        let devices = await  new Array('iphone','android');
        const mobile = await verificarDispositivo()
        if(mobile){
          return
        }
         function mobiDetect(userAgent, devices) {
          for(var i = 0; i < devices.length; i++) {
            if (userAgent.search(devices[i]) > 0) {
              return devices[i];
            }
          }
          return null;
        }
        let device = await mobiDetect(userAgent, devices);
        if (device !== null ) {
          await device == 'iphone' ? this.setState({ linkloja: 'https://apps.apple.com/br/app/vende-tudo/id1499526187'}) : this.setState({ linkloja: 'https://play.google.com/store/apps/details?id=br.com.vendetudo.vende_tudo_app'})
    
         Linking.openURL(this.state.linkloja)
          
        } else {
      
        }
      }

    render(){
        return(
            <div className="login_apss">
                </div>
        )
         }

}


export default withRouter(LojasApps);