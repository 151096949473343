import React from 'react'
import './compartilhar.css'
import whatsicon from '../../assets/images/whatsapp.png'
import faceicon from '../../assets/images/facebook.png'

class Compartilhar extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount = async () => {
        await this.verificarDispositivo()
    }

    state = {
        whats : 'api'
    }
    verificarDispositivo = async () =>{

        if (window.innerWidth < 620 ) {
            await this.setState({ whats: 'api'})
        } else {
           await this.setState({ whats: 'web'})
        }
    }

    render() {
        const linkApiFace = `https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fsharevendetudo.herokuapp.com%2F${this.props.id}&amp;src=sdkpreparse`;
        let compartilhar = this.props.compartilhar ?
            <div className="compartilhar">
                <div className="compartilharContent shadow-lg">
                    <div className="fb-share-button btnCompartilhar shadow-sm" data-href={this.props.link}
                         data-layout="button_count" data-size="small">
                        <a target="_blank"
                           href={linkApiFace}
                           className="fb-xfbml-parse-ignore"><img src={faceicon} alt="" width={64} height={64}/></a>
                    </div>
                    <div className="btnCompartilhar mx-4 shadow-sm">
                        <a target="_blank" href={`https://${this.state.whats}.whatsapp.com/send?text=${this.props.link}`}  ><img src={whatsicon} width={64} height={64} alt=""/></a>
                    </div>

                    <div className="w-100 mt-3">
                        <h5>Link de Compartilhamento</h5>
                        <input value={this.props.link} type="text" className="inputCompartilhar"/>
                    </div>
                    <button className="btnFecharCompartilar shadow-sm" onClick={this.props.onClick}>Fechar</button>
                </div>
            </div> : '';
        return (
            compartilhar
        )
    }
}

export default Compartilhar;
