import React, {useState } from 'react'
import './Relatorio.css'
import anuncios from '../../../assets/images/carrinho.png'
import anuncio from '../../../assets/images/sandero.jpeg'
import axios from 'axios'
import { BASE_URL_DJANGO } from "../../../config/api";
import {getUser, setVeiculo} from "../../../config/local";
import { withRouter } from 'react-router-dom'
import Load from '../../../components/loading/load'
import {Button, Modal} from "react-bootstrap";
import CurrencyInput from "react-currency-input";
import IntlCurrencyInput from "react-intl-currency-input";
import fundo from "../../../assets/images/fundo_1.png";

class  Relatorio extends React.Component{

    state ={
        anuncios: [],
        anunciosExpirados: [],
        loading: false,
        desativador: false,
        tipos: [],
        tipoJustificativa: 0,
        justificativa: '',
        idAnuncio: 0,
        expirados: false,
        extensor: false,
        minDate: '',
        prazo: '',
        anuncio: 0,
        perc_fipe: '0',
        user:{}
    }

    getDay = (days = 6) =>{
        var data = new Date();
        data.setDate(data.getDate() + days);
        this.setState({ minDate: `${data.getFullYear()}-${ (data.getMonth()+1 < 10 ? "0" : "")}${data.getMonth()+1}-${data.getDate()+1}`, prazo: `${data.getFullYear()}-${ (data.getMonth()+1 < 10 ? "0" : "")}${data.getMonth()+1}-${data.getDate()+1}`})
    }

    componentDidMount =  async () =>{
        const user = await getUser()
        await this.setState({ user: user })
        await this.setState({ perc_fipe: parseFloat(user.perc_fipe).toFixed(2)} )
        await this.getDay()
        if(user !== null){
            await this.getAnuncios(user.id)
            await this.getExpirados(user.id)
            await this.getTipoJustificativa();
        }else{
            await this.props.history.push('')
        }

    }

    getAnuncios = (id, filtro = '') =>{
        this.setState({ loading: true });
        let url = `${BASE_URL_DJANGO}/anuncios/fipe/${id}/`;
        if(filtro !== ''){
            url = `${url}?porcentagem=${filtro} `
        }
        axios.get(url)
            .then(res => {
                this.setState({ anuncios : res.data.data , loading: false })
            })
            .catch(err => {
                this.setState({ loading: false});
                alert('Não foi possível encontrar seus anuncios. Por favor, tente novamente mais tarde.')
            })
    }

    getExpirados = (id) =>{
        this.setState({ loading: true });
        axios.get(`${BASE_URL_DJANGO}/anuncios/?expirado=true&user=${id}`)
            .then(res => {
                this.setState({ anunciosExpirados : res.data.data , loading: false })
            })
            .catch(err => {
                this.setState({ loading: false});
                alert('Não foi possível encontrar seus anuncios. Por favor, tente novamente mais tarde.')
            })
    }


    getTipoJustificativa = async () =>{
        await axios.get(`${BASE_URL_DJANGO}/extra/tipo-justificativa/`)
            .then(res => {
                this.setState({ tipos : res.data})
            })
            .catch(err => {
                alert('Não foi possível encontrar os tipos de justificativa.');
            })
    }
    desativar = () =>{
        if(this.state.tipoJustificativa == 0){
            alert("É necessário selecionar o tipo da justificativa.");
            return;
        }
        if(this.state.justificativa.trim() == ""){
            alert("É necessário informar a justificativa da desativação do anúncio.");
            return;
        }
        const anuncio = {
            anuncio: this.state.idAnuncio,
            justificativa: this.state.justificativa,
            tpjustificativa: this.state.tipoJustificativa
        }
        axios.post(`${BASE_URL_DJANGO}/anuncio/justificativa/`, anuncio)
            .then(res => {
                alert('Anuncio desativado com sucesso.');
                this.setState({ desativador: false})
                this.componentDidMount()
            })
            .catch(err => {
                alert('Não foi possível desativar o anuncio.');
            })
    }

    verCarro = async (anuncio) =>{
        await setVeiculo(anuncio)
        await this.props.history.push(`/anuncio/detalhe/${anuncio.id}`)
    }

    abrirModal = async (id) =>{
        this.setState({ desativador: true, idAnuncio: id })
    }

    fecharModal = () =>{
        this.setState({ desativador: false })
    }

    editarAnuncio = id =>{
        this.props.history.push(`/anuncio/editar/${id}`)
    }

    estenderPrazo = id =>{
        this.setState({ extensor: true })
        axios.patch(`${BASE_URL_DJANGO}/anuncios/${id}/`,{
            prazo: this.state.prazo,
            expirado: false,
            flativo: true
        }).then( res => {
            alert("Novo prazo adicionado.")
            this.setState({ extensor: false } )
            this.componentDidMount()

        } )
            .catch(err => {
                alert('Não foi possível adicionar o novo prazo.')
                this.setState({ extensor: false } )
            })
    }

    fecharExtensor = () =>{
        this.setState({ extensor: false })
    }
    mudarPrazo = (prazo) =>{
        var data = new Date(prazo);
        data.setDate(data.getDate());
        this.setState({ prazo: `${data.getFullYear()}-${ (data.getMonth()+1 < 10 ? "0" : "")}${data.getMonth()+1}-${data.getDate()+1}`})
    }

    numberToReal = (numero) => {
        var numero = numero.toFixed(2).split('.');
        numero[0] =  numero[0].split(/(?=(?:...)*$)/).join('.');
        return numero.join(',');
    }
    convertKm = (km) =>{
        if(km !== undefined){
            return km.toLocaleString('pt-br', {minimumFractionDigits: 0})
        }
    }

    render(){
        return(
            <>
                <img src={fundo} className={"imgBackground"} alt=""/>
            <div className="resumo mx-auto">
                <div className="resumoContainer text-left">
                    <div className="resumoButtons d-flex flex-column p-3 mx-auto my-1">
                        <h2 className={'text-white mr-3'}>Relatório de anúncios abaixo da FIPE</h2>
                        <h6 className={"text-white"}>Filtrar por porcentagem (%)</h6>
                        <div className={"d-flex align-items-center"}>
                            <IntlCurrencyInput
                                value={this.state.perc_fipe}
                                className="inputPerfil tamanhoCard p-1 bg-white mb-3 mr-1"
                                onChange={(e,v,mask) => this.setState({ perc_fipe: mask  })}
                                currency="BRL" config={{
                                locale: "pt-BR",
                                formats: {
                                    number: {
                                        BRL: {
                                            currency: "BRL",
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        },
                                    },
                                }
                            }}
                            />
                            <button type={"button"} className={"btn btn-warning p-1 mb-3"} onClick={() => {
                                this.getAnuncios(this.state.user.id, this.state.perc_fipe.toString().replace(".","").replace(",","."))
                            }}>{'Pesquisar'}</button>
                        </div>

                        {/*<button className="btn btn-sm btn-info m-2" onClick={() => this.setState({ expirados: false })}>Publicados({this.state.anuncios.length})</button>*/}
                        {/*<button className="btn btn-sm btn-info m-2" onClick={() => this.setState({ expirados : true })}>Expirados({this.state.anunciosExpirados.length})</button>*/}
                        {/*<button className="btn btn-sm btn-info m-2">Aguardando publicação(0)</button>*/}

                    </div>
                    <Load loading={this.state.loading} />
                    <div className="resumoConteudo mx-auto p-3">

                        {
                            !this.state.expirados ?
                                this.state.anuncios.map(
                                    item =>
                                        <div className="btn m-1 text-left bg-light rounded-lg btnCard cardFipe">
                                            <button onClick={() => this.verCarro(item)} className="publicacaoCard btn m-1 text-left bg-light rounded-lg">
                                                <b>
                                                    { item.infoCar !== null ? item.infoCar.Modelo : '-'}
                                                </b> <br/>
                                                <div className="d-flex">
                                                    <div className={"fotoFipe"}>
                                                        {item.image_anuncio.length > 0 ? <img src={item.image_anuncio[0].foto} className="py-2"/> : "" }
                                                    </div>

                                                    {/**/}
                                                    <p className="p-2">
                                                        <strong>R$ { this.numberToReal(parseInt(item.valor))}</strong> <br/>
                                                        {item.ano}/{ item.infoCar !== null ? item.infoCar.AnoModelo : '-'} <br/>
                                                        {item.cor.nome} <br/>
                                                        {this.convertKm(item.km)}Km <br/>
                                                        {item.user_info.cidade}
                                                    </p>
                                                </div>
                                                <div className={""}>
                                                    <h6 style={{color: '#019FA8'}}>Valor Fipe { item.infoCar.Valor } - abaixo { item.porcet_fipe.replace(".",",") }%</h6>
                                                </div>
                                            </button>
                                            {/*<div className="w-100 text-right">*/}
                                            {/*    <button className="btn btn-info" onClick={() => this.abrirModal(item.id)}>Desativar</button>*/}
                                            {/*    <button className="btn btn-warning ml-2" onClick={() => this.editarAnuncio(item.id)}>Editar</button>*/}
                                            {/*    /!*{item.usuario} -  <i>{item.hora.getDate()}/{item.hora.getMonth() + 1}/{item.hora.getFullYear()} {item.hora.getHours()}:{item.hora.getMinutes()}:{item.hora.getSeconds()}</i>*!/*/}
                                            {/*</div>*/}
                                        </div>
                                ):
                                this.state.anunciosExpirados.map(
                                    item =>
                                        <div className="btn m-1 text-left bg-light rounded-lg btnCard">
                                            <button onClick={() => this.verCarro(item)} className="publicacaoCard btn m-1 text-left bg-light rounded-lg">
                                                <b>
                                                    { item.infoCar !== null ? item.infoCar.Modelo : '-'}
                                                </b> <br/>
                                                <div className="d-flex">
                                                    {item.image_anuncio.length > 0 ? <img src={item.image_anuncio[0].foto} className="py-2" height="100"/> : "" }
                                                    {/**/}
                                                    <p className="p-2">
                                                        <strong>R$ {item.valor}</strong> <br/>
                                                        { item.infoCar !== null ? item.infoCar.AnoModelo : '-'} <br/>
                                                        {item.cor.nome} <br/>
                                                        {item.km}Km <br/>
                                                    </p>
                                                </div>
                                            </button>
                                            {/*<div className="w-100 text-right">*/}
                                            {/*    <button className="btn btn-info" onClick={() => this.abrirModal(item.id)}>Desativar</button>*/}
                                            {/*    <button className="btn btn-warning ml-2" onClick={() => this.setState({ extensor: true, anuncio: item.id })}>Estender prazos</button>*/}
                                            {/*    /!*{item.usuario} -  <i>{item.hora.getDate()}/{item.hora.getMonth() + 1}/{item.hora.getFullYear()} {item.hora.getHours()}:{item.hora.getMinutes()}:{item.hora.getSeconds()}</i>*!/*/}
                                            {/*</div>*/}
                                        </div>

                                )}
                    </div>
                </div>
                <>
                    <Modal show={this.state.desativador} onHide={this.fecharModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Desativar Anúncio</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form >
                                <label>Tipo de Justificativa</label>
                                <select onChange={ tipo => this.setState({ tipoJustificativa: tipo.target.value })} className="form-control">
                                    <option value={0}>Selecione...</option>
                                    {this.state.tipos.map(it => <option value={it.id}>{it.descricao}</option>)}
                                </select>
                                <label htmlFor="valor">Justificativa</label>
                                <textarea name="observacao" value={this.state.observacao} className="form-control" cols="30" onChangeCapture={ js => this.setState({ justificativa : js.target.value })} rows="3"></textarea>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.fecharModal()}>
                                Fechar
                            </Button>
                            <Button variant="primary" onClick={() => this.desativar()}>
                                Enviar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={this.state.extensor} onHide={this.fecharExtensor}>
                        <Modal.Header closeButton>
                            <Modal.Title>Estender Prazo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form >
                                <label>Prazo</label>
                                <input min={this.state.minDate} value={this.state.prazo} onChange={v => this.mudarPrazo(v.target.value)} className="form-control" type="date"/>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.fecharExtensor()}>
                                Fechar
                            </Button>
                            <Button variant="primary" onClick={() => this.estenderPrazo(this.state.anuncio)}>
                                Enviar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
                <div>
          <img height="1" width="1"
            src="https://www.facebook.com/tr?id=1438661459663519?ev=PageView?noscript=1" />
        </div>
            </div>
            </>
        )
    }



}

export default withRouter(Relatorio);
