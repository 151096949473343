import React, { Component } from 'react'
import './Formulario.css'
import axios from 'axios'
import fundo from '../../../assets/images/fundo_1.png'
import { BASE_URL_PHP } from '../../../config/api'
import { getVeiculo, getUser, removeVeiculo } from "../../../config/local";
import CurrencyInput from 'react-currency-input';
import InputMask from 'react-input-mask'
import veiculo from "../../../services/veiculo/veiculo";
import Load from "../../../components/loading/load";
import IntlCurrencyInput from "react-intl-currency-input"

class Formulario extends React.Component {

    state = {
        valortotal: '',
        valorentrada: '0',
        nome: '',
        email: '',
        telefone_ddd: '',
        telefone_numero: '',
        "idmarca": 6,
        "codFipe": "7418",
        "codfipe2": "2017-1",
        "idcidade": 1,
        ano: 0,
        resultado: [],
        loading: false,
        id: 0,
        dados: {},
        anos: []
    }

    componentDidMount = async () => {
        const veiculo = await getVeiculo();
        await this.getAnos();
        if (veiculo !== null)
            await this.setState({ valortotal: veiculo.valor.replace(".", ",") || '' })
        const user = await getUser();
        if (user !== null)
            await this.setState({
                nome: user.nome || "",
                email: user.email || "",
                telefone_ddd: user.telefone ? user.telefone.ddd : '',
                telefone_numero: user.telefone ? user.telefone.numero : ""
            })
        window.scrollTo(0, 0)
    }
    componentWillUnmount() {
        removeVeiculo()
    }

    getAnos = () => {
        axios.get(`${BASE_URL_PHP}/api/financiamento`).then(res => this.setState({ anos: res.data }))
            .catch(err => alert('Não foi possível carregar os anos. Por favor tente novamente mais tarde.'))
    }

    simular = () => {
        /* if (this.state.ano == 0) {
            return alert('Por favor, selecione um ano válido.')
        }*/

        this.setState({ loading: true }, () => {
            const formulario = {
                valortotal: this.state.valortotal.replace(".", "").replace(",", "."),
                valorentrada: this.state.valorentrada.replace(".", "").replace(",", "."),
                nome: this.state.nome,
                email: this.state.email,
                telefone_ddd: this.state.telefone_ddd.toString().replace("(", "").replace(")", ""),
                telefone_numero: this.state.telefone_numero,
                idmarca: this.state.idmarca,
                codFipe: this.state.codFipe,
                codfipe2: this.state.codfipe2,
                idcidade: this.state.idcidade,
                ano: this.state.ano,
                flmobile: false
            }
            let valid = parseInt(formulario.valortotal) > 0;
            /* if (!valid) {
                 alert("Por favor verifique o campo de valor total.");
                 this.setState({ loading: false });
                 return;
             }
             if (this.state.nome.trim() == "") {
                 alert("Por favor insira seu nome.");
                 this.setState({ loading: false });
                 return;
             }
             if (this.state.email.trim() == "") {
                 alert("Por favor insira seu email.");
                 this.setState({ loading: false });
                 return;
             }
             if (formulario.telefone_ddd.trim() == "" || formulario.telefone_numero.trim() == "") {
                 alert("Por favor verifique o telefone ddd e numero inserido.");
                 this.setState({ loading: false });
                 return;
             }*/


            /*  if (parseInt(formulario.valortotal) > parseInt(formulario.valorentrada)) {*/
            axios.post(`${BASE_URL_PHP}/api/financiamento`, formulario)
                .then(res => this.setState({ resultado: res.data.parcelas, id: res.data.id, loading: false, dados: res.data }))
                .catch(err => {
                    this.setState({ loading: false });
                    let erros = '\n';
                    const campos = Object.keys(err.response.data).map(it => {
                        
                        return erros = erros  + err.response.data[it] + ''
                        
                    })
                    alert("Por favor, verifique se os campos: " + erros)
                })
            /*  } else {
                  alert("Valor total deve ser maior que o valor de entrada.")
                  this.setState({ loading: false })
              }*/
            window.scrollTo(0, 0)
        })
    }

    tenhoInteresse = () => {
        this.setState({ loading: true })
        axios.put(`${BASE_URL_PHP}/api/financiamento/contato`, {
            id: this.state.id
        }).then(res => {
            this.setState({ loading: false })
            alert('Nossa equipe de analistas entrará em contato com você em breve!')
            this.setState({ resultado: [] })
        }).catch(err => {
            this.setState({ loading: false })
            alert('Não foi possível realizar sua solicitação, por favor, tente novamente mais tarde.')
        })
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <>
                <img src={fundo} className={"imgBackground"} alt="" />
                <div className="formulario">

                    <Load loading={this.state.loading} />
                    <h1>Simulador de Financiamento</h1>
                    <h3>Faça uma simulação e veja o valor da parcela do seu próximo carro</h3>
                    <div className="container">

                        {this.state.resultado.length > 0 ?
                            <div className="row p-5">
                                <table className={"table bg-white text-left"}>
                                    <t>
                                        <td>Dados da Simulação</td>
                                    </t>
                                    <tbody>
                                        <tr className={'w-100'}>
                                            <td>
                                                <b>Nome:</b> {this.state.dados.nome} <br />
                                                <b>Valor do Veículo:</b> {parseFloat(this.state.dados.valortotal).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} <br />
                                                <b>Valor de Entrada:</b> {parseFloat(this.state.dados.valorentrada).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} <br />
                                                <b>Ano do Veículo:</b> {this.state.dados.ano_veiculo} <br />
                                                <b>Data da Simulação:</b> {this.state.dados.data}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table bg-white shadow">
                                    <t>
                                        <td>Qtd. Parcelas</td>
                                        <td>Valor p/Parcela</td>
                                    </t>
                                    <tbody>
                                        {this.state.resultado.map(
                                            (item, idx) =>
                                                <tr key={idx}>
                                                    <td>{item.nparcelas}</td>
                                                    <td>{item.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                                </tr>
                                        )}
                                    </tbody>
                                </table>
                                <div className={"text-center"}>
                                    <small>Taxas sujeitas a alteração,análise de credito e aprovação. O VendeTudo.com pode entrar em contato por telefone ou e-mail para confirmações de dados ou obtenção de informações adicionais,bem como para tratar da proposta.</small>
                                </div>
                                <div className="col-md-6 my-2 d-md-flex align-items-center flex-row mx-auto">
                                    <button onClick={() => {
                                        this.setState({ resultado: [] })
                                        window.scrollTo(0, 0)
                                    }
                                    } className="btn btn-info shadow my-2">Refazer</button>
                                    <button onClick={() => this.tenhoInteresse()} className="btn mx-md-2 btn-warning shadow">Tenho Interesse</button>
                                </div>
                            </div>
                            :
                            <div className="row p-5 text-left">
                                <div className="col-md-6 my-2">
                                    <label htmlFor="">Valor Total R$</label>
                                    {/*<CurrencyInput allowEmpty={true} decimalSeparator="," thousandSeparator="." value={this.state.valortotal} className="form-control" onChangeEvent={ valor => this.setState({ valortotal: valor.target.value  })} placeholder="Valor da Total" />*/}
                                    <IntlCurrencyInput
                                        value={this.state.valortotal}
                                        className="form-control"
                                        onChange={(e, v, mask) => this.setState({ valortotal: mask })}
                                        currency="BRL" config={{
                                            locale: "pt-BR",
                                            formats: {
                                                number: {
                                                    BRL: {
                                                        currency: "BRL",
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    },
                                                },
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-md-6 my-2">
                                    <label htmlFor="">Valor Entrada R$</label>
                                    {/*<CurrencyInput allowEmpty={true} decimalSeparator="," thousandSeparator="." value={this.state.valorentrada} className="form-control" onChangeEvent={ valor => this.setState({ valorentrada: valor.target.value })} placeholder="Valor da Entrada" />*/}
                                    {/*<input type="number" value={this.state.valorentrada } className="form-control" onChange={ valor => this.setState({ valorentrada: valor.target.value })} placeholder="Valor Entrada" />*/}
                                    <IntlCurrencyInput
                                        value={this.state.valorentrada}
                                        onChange={(e, v, m) => this.setState({ valorentrada: m })}
                                        className="form-control"
                                        currency="BRL" config={{
                                            locale: "pt-BR",
                                            formats: {
                                                number: {
                                                    BRL: {
                                                        // style: "currency",
                                                        currency: "BRL",
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    },
                                                },
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-md-6 my-2">
                                    <label htmlFor="">Nome</label>
                                    <input type="text" value={this.state.nome} className="form-control" onChange={nome => this.setState({ nome: nome.target.value })} placeholder="Seu nome" />
                                </div>
                                <div className="col-md-6 my-2">
                                    <label htmlFor="">Email</label>
                                    <input type="email" value={this.state.email} className="form-control" onChange={email => this.setState({ email: email.target.value })} placeholder="Email" />
                                </div>
                                <div className="col-md-2 my-2">
                                    <label htmlFor="">DDD</label>
                                    <InputMask placeholder="DDD" className="form-control" value={this.state.telefone_ddd} onChange={vl => this.setState({ telefone_ddd: vl.target.value })} mask={"(99)"} />
                                    {/*<input type="text" className="form-control" onChange={ telefone => this.setState({ telefone_ddd: telefone.target.value })} placeholder="Telefone"/>*/}
                                </div>
                                <div className="col-md-4 my-2">
                                    <label htmlFor="">Telefone</label>
                                    <InputMask placeholder="Numero" className="form-control" value={this.state.telefone_numero} onChange={vl => this.setState({ telefone_numero: vl.target.value })} mask={"99999-9999"} />
                                </div>
                                <div className="col-md-6 my-2">
                                    <label htmlFor="">Ano do Veículo</label>
                                    <select className="form-control" onChange={vl => this.setState({ ano: vl.target.value })}>
                                        <option value="">Selecione o ano...</option>
                                        {this.state.anos.map(it => 
                                            <option value={it.ano}>{it.ano}</option>
                                        )}
                                    </select>
                                    {/*<Select*/}
                                    {/*    value={this.state.ano !== 0 ? this.state.anos.filter(ano => ano.id === this.state.ano ).map(m1 => { return { value: m1.ano, label: m1.ano  } }) : ''}*/}
                                    {/*    className=" my-1"*/}
                                    {/*    placeholder={"Selecione o ano"}*/}
                                    {/*    options={this.state.anos.map(it => { return { value: it.ano, label: it.ano }})}*/}
                                    {/*    onChange={ano => this.setState({ ano: ano.value} )}*/}
                                    {/*    disabled={!this.state.edit}*/}
                                    {/*/>*/}
                                </div>
                                {/* <div className="col-md-6 my-2">
                            <input type="text" className="form-control" placeholder="CPF"/>
                        </div> */}
                                {/* <div className="col-md-6 my-2">
                            <input type="email" className="form-control" placeholder="E-mail"/>
                        </div> */}
                                <div className="col-md-12 my-2 mx-auto d-flex flex-row justify-content-center">
                                    <button onClick={() => this.simular()} className="btn btn-info shadow">SIMULAR</button>
                                </div>
                            </div>
                        }
                    </div>
                    <div>
                        <img height="1" width="1"
                            src="https://www.facebook.com/tr?id=1438661459663519?ev=PageView?noscript=1" />
                    </div>
                </div>
            </>
        )
    }


}

export default Formulario;
