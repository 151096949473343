import React, {Component} from 'react';
import ReactDOM from 'react-dom'
import './Header.css';
import logo from '../../assets/logos/logos vende tudo/140 x 60/LOGO VENDE TUDO - BRANCA.png'
import icon1 from '../../assets/images/icone_1.png'
import {withRouter} from 'react-router-dom'
import {Dropdown} from "react-bootstrap";
import {getUser, removeUser} from '../../config/local'
import Notificacoes from "../Notificacoes/Notificacoes";
import axios from "axios";
import {BASE_URL_DJANGO} from "../../config/api";
import Drawer from '@material-ui/core/Drawer';


class Header extends React.Component {

    state = {
        autenticado: false,
        routerNotShow: [
            '/cadastro',
            '/login',
            '/redefinirsenha/'
        ],
        nome: '',
        load: false,
        notificacao: false,
        temNotificacao: false,
        notificacoes: [],
        contnotificacoes: 0,
        displaynotificacao : 'none',
        intervalo :'',
        cont : 0
        
    };

    componentDidMount = () => {
        this.verificarUser();
        setInterval(() =>{
            this.setState({ temNotificacao: false })
            this.getNotificacoes(true)
        }, 10000)    
    }


    getNotificacoes =  (sync = false) =>{
        const user =  getUser();
       
        if(user && !this.state.temNotificacao){
            axios.get(`${BASE_URL_DJANGO}/notificacoes/?user=${user.id}`).then( res => {
                if(res.data.length > 0){
                    this.setState({ notificacoes : res.data })
                    this.setState({ temNotificacao: true })
                    this.setState({contnotificacoes : parseInt(res.data.length)})
                    this.setState({displaynotificacao : 'initial'})   
                }else{
                   // this.setState({displaynotificacao : 'none'})
                }
            }).catch(err => alert('Não foi possível carregar as notificações.'))
        }else if(sync){
            /*axios.get(`${BASE_URL_DJANGO}/notificacoes/?user=${user.id}`).then( res => {
                if(res.data.length = 0){
                    this.setState({displaynotificacao : 'none'})
                }
            }).catch(err => alert('Não foi possível carregar as notificações.'))*/
        }
    }

    verificarUser = async () => {
        const user = await getUser();
        if (user !== null) {
            this.state.nome = user.nome
            await this.setState({nome: user.nome.split(" ")[0]}, () => {
                this.setState({ autenticado: true })
            })
        } else {
            this.setState({autenticado: false})
        }
    }


    logout = async () => {
        await removeUser();
        await this.setState({ autenticado: false, temNotificacao: false })
        await this.props.history.push("/")
    }

    abrirNotificacoes = () =>{
        this.getNotificacoes(true)
        this.setState({ notificacao: true})
    }

    abrirLink = async (link,id) =>{
        await this.setState({ notificacao: false })
        if(link.trim() !== ""){
            await axios.delete(`${BASE_URL_DJANGO}/notificacoes/${id}/`).then( res => this.getNotificacoes() )
                .catch(err => alert('Não foi possível carregar a notificação.'))
            await this.props.history.push(`/chat`)
            await this.props.history.push(`/conversa${link}`)
        }else{
            await alert("Não foi possível buscar os dados da notificação.")
        }
    }
    renderNotificacao = () =>{
        return this.state.notificacoes.map(
            not =>
                <>
                    <div className={"notificacaoItem"}>
                        <div className={"notItemHeader"} onClick={() => this.abrirLink(not.url_web || '', not.id)}>
                            <b className={"text-dark"}>{not.title || ''}</b>
                            <p>{not.description || ''}</p>
                        </div>
                    </div>
                </>
        )
    }

    componentLogged = () => {
        const user = getUser();
        this.getNotificacoes();
        if (user === null)
            return (
                <div className="container menuContent font-weight-bold">
                    <div className={"menuItens"}>
                        <a className="menuBrand mr-3" href="/"><img src={logo}/></a>
                    </div>
                    <div>
                        <a className="btn btn-warning" href="/login">Anunciar</a>
                        <a href="/login" className={"btn btn-light text-laranja ml-2"}>Entrar</a>
                    </div>
                </div>
            );
        return (
            <>

                <React.Fragment key={true}>
                    <Drawer anchor={'right'} open={this.state.notificacao} onClose={() => this.setState({ notificacao: false})}>
                        <div className="notificacoesHeader">
                            <h6 className={"p-2 ml-2 pt-3"}>Notificações</h6>
                            <button type={"button"} className={"btn btn-sm btn-danger px-4 my-2 mr-2"} onClick={() => this.setState({ notificacao: false })}><i className={'fas fa-arrow-right'}></i></button>
                        </div>
                        <div className={"notificacaoList"}>
                            {this.renderNotificacao()}
                        </div>
                    </Drawer>
                </React.Fragment>
                {/*<Notificacoes visible={this.state.notificacao} closeButton={() => this.setState({ notificacao: false })}/>*/}
                <div className="container menuContent font-weight-bold">

                    <div className={"menuItens"}>
                        <a className="menuBrand mr-0 mr-md-3 logocel" href="/"><img src={logo}/></a>
                    </div>

                    <div className={"d-flex ml-auto flex-row align-items-center justify-content-center celularalinha"} id={"idnotificacao"}>
                        <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                <i className={"fas fa-user"}></i><i className="nomelogin"> Olá, {this.state.nome || user.nome.split(" ")[0]}</i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="/perfil">Meu perfil</Dropdown.Item>
                                <Dropdown.Item href="/atividade/resumo">Meus anuncios</Dropdown.Item>
                                <Dropdown.Item href="/favoritos">Favoritos</Dropdown.Item>
                                {/*<Dropdown.Item onClick={() => this.abrirNotificacoes()}>Notificações {this.state.temNotificacao ? <i className={"fas fa-bell notifHeader"}></i> : ''} </Dropdown.Item>*/}
                                <Dropdown.Item href="/chat">Chat</Dropdown.Item>
                                {user.id_tipo === 2 ? <Dropdown.Item href="/relatorio">Relatório Fipe </Dropdown.Item>  : ''}
                                {user.id_tipo === 2 ? <Dropdown.Item href="/querocomprar">Relatório Quero Comprar </Dropdown.Item>  : ''}
                                <Dropdown.Item onClick={() => this.logout()}>Sair</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <a className="ml-1 ml-md-3 btnAnunciar btn btn-warning " href="/anuncio/cadastro">Anunciar</a>
                        <button className={`ml-1 ml-md-3 btn-light btn btnBell`} onClick={() => this.abrirNotificacoes()} > <i className={`fas fa-bell notifHeader ${this.state.temNotificacao ? 'bellTrue' : 'bellFalse'}`}></i>
                        <span style= {{display:this.state.displaynotificacao}} className={'contnotificacao'}>{this.state.contnotificacoes}</span>
                        </button>

                    </div>
                </div>
            </>
        );

    }

    render(){
        const currentPath =  this.props.location.pathname;
        const current =  this.state.routerNotShow.filter(path => path == currentPath);


      


        if (current.length == 0) {
            return (
                <div className="menu">
                    {this.componentLogged()}
                </div>
            )
        } else {
            return ''
        }

    }
}

export default withRouter(Header)