import React, { useState } from 'react'
import './Editar.css'
import mensagem from '../../../assets/images/mensagem.png'
import calendario from '../../../assets/images/calendar.png'
import axios from 'axios'
import { BASE_URL_DJANGO } from '../../../config/api'
import { getUser } from "../../../config/local";
import Load from '../../../components/loading/load'
import { withRouter, useParams } from 'react-router-dom'
import CurrencyInput from 'react-currency-input';
import Select from "react-select";
import IntlCurrencyInput from "react-intl-currency-input";

class Editar extends React.Component {

    state = {
        marcas: [],
        modelos: [],
        versoes: [],
        caractSelec: [],
        caracteristica: [],
        cores: [],
        tiposcomb: [],
        combustivel: 0,
        idmarca: 0,
        codfipe: 0,
        codfipe2: 0,
        valor: '0,00',
        prazo: new Date(),
        observacao: '',
        km: '0',
        user: 0,
        cor: 0,
        cod: '',
        image_anuncio: [],
        imgOriginais: [],
        fotosExcluir: [],
        fotos: [],
        imagens: [],
        fotos_upload: [],
        anunciante: {},
        infoCar: {},
        caracteristicas: [],
        anuncio: {},
        loading: false,
        minDate: `${new Date().getFullYear()}-${(new Date().getMonth() + 1 < 10 ? "0" : "")}${new Date().getMonth() + 1}-${new Date().getDate() + 1}`,
        atualcategoria: 0
    }

    componentDidMount = async () => {
        const user = await getUser();
        await this.setState({ user: user.id })
        const { match: { params } } = await this.props;
        await this.getVeiculo(params.id)
        // this.detectanavegador()
       
    }

    getVeiculo = (id) => {
        this.setState({ loading: true })
        axios.get(`${BASE_URL_DJANGO}/anuncios/${id}/`)
            .then(res => {
                this.setState({ anuncio: res.data })
                this.setState({ infoCar: res.data.infoCar })
                this.setState({ anunciante: res.data.user_info })
                this.setState({ caracteristicas: res.data.caracteristica })
                this.setState({ imagens: res.data.image_anuncio.map(img => img.foto) })
                this.setState({ imgOriginais: res.data.image_anuncio })
                this.setState({ loading: false })
                this.setState({ atualcategoria: res.data.tipo_veiculo })
                this.setState({
                    valor: this.state.anuncio.valor.replace('.', ','),
                    prazo: this.state.anuncio.prazo,
                    minDate: this.state.anuncio.prazo,
                    observacao: this.state.anuncio.observacao,
                    km: this.state.anuncio.km.toString().replace(".000"),
                    codfipe: this.state.anuncio.codfipe,
                    codfipe2: this.state.anuncio.codfipe2,
                    fotos: this.state.imagens,
                    cor: this.state.anuncio.cor.id,
                    caracteristica: res.data.caracteristica.map(ca => ca.id),
                    ano: this.state.anuncio.ano,
                    combustivel: this.state.anuncio.combustivel !== null ? this.state.anuncio.combustivel.id : 0
                })
                this.setState({
                    caractSelec: this.state.caractSelec.map(it => {
                        if (this.state.caracteristica.indexOf(it.id) !== -1) {
                            it.check = true
                        } else {
                            it.check = false
                        }
                        return it
                    })
                })
                this.carregaCategoria()
                this.getModelos(this.state.anuncio.idmarca)
                this.getMarcas(this.state.atualcategoria)
            })
            .catch(err => {
                this.setState({ loading: false })
                alert('Não foi possível encontrar os dados do anuncio.')
            })
    }

    getTipos = () => {
        axios.get(`${BASE_URL_DJANGO}/extra/combustivel/`)
            .then(res => {
                this.setState({ tiposcomb: res.data })
            })
            .catch(err => console.log(err))
    }


    getMarcas = (categoria) => {
        if (categoria == 0) {
            alert('Selecione uma categoria válida')
        } else {
            axios.get(`${BASE_URL_DJANGO}/fipe/carros/marcas/${categoria}/`)
                .then(res => {
                    this.setState({ marcas: res.data.data })
                    this.state.atualcategoria = categoria;
                  
                })
                .catch(err => console.log(err))
        }
    }

    getCaracteristicas = () => {
        axios.get(`${BASE_URL_DJANGO}/extra/carasteristicas/`)
            .then(res => {
                this.setState({ caractSelec: res.data })
            })
            .catch(err => console.log(err))
    }

    getCores = () => {
        axios.get(`${BASE_URL_DJANGO}/extra/cor/`)
            .then(res => {
                this.setState({ cores: res.data })
            })
            .catch(err => console.log(err))
    }


    getModelos = (marca) => {
        if (marca == 0) {
            alert('Selecione uma marca válida')
        } else {
            const nova = marca !== this.state.anuncio.idmarca && this.state.anuncio.idmarca !== 0
            axios.get(`${BASE_URL_DJANGO}/fipe/carros/veiculos/${marca}/${this.state.atualcategoria}/`)
                .then(res => {
                    this.setState({ idmarca: marca })
                    this.setState({ modelos: res.data.data.Modelos })
                    if (!nova) {

                        this.getVersao(this.state.anuncio.codfipe)
                    } else {
                        this.setState({
                            codfipe2: 0
                        })
                    }

                })
                .catch(err => console.log(err))
        }
    }

    getVersao = (modelo, nova = false) => {
        if (modelo == 0) {
            alert('Selecione um modelo válido.')
        } else {
            if (nova) {
                this.setState({ codfipe2: 0 })
            }
            axios.get(`${BASE_URL_DJANGO}/fipe/carros/veiculo/${this.state.idmarca}/${modelo}/${this.state.atualcategoria}/`)
                .then(res => {
                    this.setState({ codfipe: modelo, versoes: res.data.data })
                })
                .catch(err => console.log(err))
        }
    }

    setCaracteristica = id => {
        let caracteristicas = this.state.caracteristica;
        const index = caracteristicas.indexOf(parseInt(id))
        if (index !== -1) {
            caracteristicas.splice(index, 1);
        } else {
            caracteristicas.push(parseInt(id));
        }
        this.setState({ caracteristica: caracteristicas })
    }

    componentWillMount = () => {
       
        this.getCaracteristicas()
        this.getCores()
        this.getTipos()
       
    }



    uploadImagem = (event, i) => {
        const files = event.target.files;

        let fotos = this.state.fotos;
        let fotosUp = this.state.fotos_upload

        for (let i = 0; i < files.length; i++) {
            if (fotos.length === 10) {
                alert("Limite máximo de fotos permitido.")
            } else {
                const tipo = event.target.files[i].type.replace("image/", "")
                if (tipo !== 'jpeg' && tipo !== 'png' && tipo !== 'jpg') {
                    alert("Formato de imagem inválido. Por favor selecione uma imagem nos formatos: .png , .jpg  ou .jpeg")
                } else {
                    const avatar = URL.createObjectURL(files[i])
                    fotosUp.push(files[i]);
                    fotos.push(avatar);
                }
            }


        }
        this.setState({
            fotos: fotos,
            fotos_upload: fotosUp
        })
    }
    removeImagem = (i, foto) => {
        let fotos = this.state.fotos;
        let fotosUp = this.state.fotos_upload;

        fotos = fotos.filter((it, idx) => idx !== i);
        fotosUp = fotosUp.filter((it, idx) => idx !== i);
        let fotoOriginal = this.state.imgOriginais.filter((ft, idx) => ft.foto === foto);
        this.setState({
            fotos: fotos,
            fotos_upload: fotosUp
        })
        if (fotoOriginal.length > 0) {
            let fotosexc = this.state.fotosExcluir
            fotosexc.push(fotoOriginal[0]);
            this.setState({
                fotosExcluir: fotosexc
            }, () => { })
        }
    }

    removerFotos = () => {
        let ids = [];
        this.state.fotosExcluir.map(it => {
            axios.delete(`${BASE_URL_DJANGO}/anuncio/fotos/${it.id}/`)
                .then(res => { ids.push(it.id) })
                .catch(err => alert("Não foi possível remover a foto."))
        })
    }
    criarAnuncio = () => {
        this.setState({ loading: true })
        const fotoCountUp = this.state.fotos.length
        if (fotoCountUp <= 0) {
            this.setState({ loading: false });
            alert("Por favor, insira no minímo 1(uma) foto para o anúncio.")
            return
        }

        const data = new Date(this.state.prazo);
        // const dataFormatada = `${data.getDate() + 1 < 10 ? 0: ''}${data.getDate() + 1}/${data.getUTCMonth() + 1  < 10 ? '0' : ''}${data.getUTCMonth()+1}/${data.getFullYear()}`;

        let anuncio = {
            codfipe: this.state.codfipe,
            codfipe2: this.state.codfipe2,
            valor: this.state.valor.replace(".", "").replace(",", "."),
            cor: this.state.cor == 'Selecione...' ? null : this.state.cor,
            idmarca: this.state.idmarca,
            km: parseInt(this.state.km.toString().replace(".", "").replace(",", ".")),
            ano: this.state.ano,
            tipo_veiculo: this.state.atualcategoria,
            user: this.state.user,
            //  prazo: dataFormatada,
            observacao: this.state.observacao,
            caracteristica: this.state.caracteristica,
            combustivel: this.state.combustivel == 'Selecione...' ? null : this.state.combustivel
        }
        axios.patch(`${BASE_URL_DJANGO}/anuncios/${this.state.anuncio.id}/`, anuncio)
            .then(async res => {
                if (this.state.fotosExcluir.length > 0) {
                    await this.removerFotos()
                }
                let i = await 0;
                var form = await new FormData();
                while (i < fotoCountUp) {
                    if (this.state.fotos_upload[i] !== undefined) {
                        await form.append('local', this.state.fotos_upload[i])
                        await form.append('anuncio', res.data.id)
                        await axios.post(`${BASE_URL_DJANGO}/anuncio/fotos/`, form).then(res => console.log('Foto Inserida'))
                            .catch(err => {
                                this.setState({ loading: false });
                                alert('A foto inserida não pode ser carregada.');
                            })
                    }
                    await i++;
                }
                await this.setState({ loading: false })
                await alert("Anuncio Editado com sucesso.");
                await this.props.history.push("/atividade/resumo")
            }
            )
            .catch(err => {
                this.setState({ loading: false });
                let erros = '\n';
                const campos = Object.keys(err.response.data).map(it => {

                    return erros = erros + it + ': ' + err.response.data[it] + '\n'
                })
                alert("Por favor, verifique se os campos: " + erros)
            })
    }
    somenteNumero = (evt) => {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        //var regex = /^[0-9.,]+$/;
        var regex = /^[0-9.]+$/;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    carregaCategoria = () => {
        window.document.getElementById("combocategoria").value = this.state.atualcategoria;
    }

    /* detectanavegador = (evt)=>{
         // Detect Firefox 1.0+
         var isFirefox = typeof InstallTrigger !== 'undefined';
         //Hide 
         if (isFirefox) {
             document.querySelector('.anotherBrowser').style.display = 'none';
             console.log("é firefox");
         } else {
             document.querySelector('.firefox').style.display = 'none';
            
         }
     }*/

    render() {
        return (

            <div className="cadastro py-5">
                <Load loading={this.state.loading} />
                <div className="container">

                    <div className="cadastroConteudo mx-auto p-1 py-3 p-md-4">
                        <h2 className="text-center mb-5">Agora ficou mais fácil vender seu veículo</h2>
                        <form className="container-fluid" >
                            <div className="row">

                                <div className="col-md-6 col-12 row form-group divcategoria">
                                    <label className="col-5">Categoria</label>

                                    <select
                                        id="combocategoria"
                                        name="combocategoria"
                                        className="col-7 my-1 form-control select2 inputCategoria"
                                        onChange={categoria => this.getMarcas(categoria.target.value)}
                            
                                    >
                                        <option value={0} >Selecione...</option>
                                        <option value={1}>Carros</option>
                                        <option value={2}>Motos</option>
                                        <option value={3}>Caminhões e Micro-ônibus</option>
                                    </select>

                                </div>
                                <div className="col-md-6 col-12 row form-group">
                                    <label className="col-5">Marca</label>
                                    {/*<select value={this.state.idmarca} className="form-control col-6 my-1" onChange={ marca => this.getModelos(marca.target.value)}>*/}
                                    {/*    <option value="0">Selecione...</option>*/}
                                    {/*    {this.state.marcas.map( marca =>*/}
                                    {/*        <option key={marca.Value} value={marca.Value}>{marca.Label}</option>*/}
                                    {/*    )}*/}
                                    {/*</select>*/}
                                    <Select
                                        value={this.state.anuncio.idmarca !== 0 ? this.state.marcas.filter(m => m.Value == this.state.idmarca).map(m1 => { return { value: m1.Value, label: m1.Label } }) : ''}
                                        className="col-7 my-1 select2 inputMarca"
                                        placeholder={"Selecione..."}
                                        options={this.state.marcas.map(it => { return { value: it.Value, label: it.Label } })}
                                        onChange={marca => this.getModelos(marca.value)}
                                    />
                                </div>
                                <div className="col-md-6 col-12 row form-group">
                                    <label className="col-5">Modelo</label>
                                    {/*<select value={this.state.codfipe} className="form-control col-6 my-1" onChange={ modelo => this.getVersão(modelo.target.value)}>*/}
                                    {/*    <option value="0">Selecione...</option>*/}
                                    {/*    {this.state.modelos.map( modelo =>*/}
                                    {/*        <option key={modelo.Value} value={modelo.Value}>{modelo.Label}</option>*/}
                                    {/*    )}*/}
                                    {/*</select>*/}
                                    <Select
                                        value={this.state.anuncio.codfipe !== 0 ? this.state.modelos.filter(m => m.Value == this.state.codfipe).map(m1 => { return { value: m1.Value, label: m1.Label } }) : ''}
                                        className="col-7 my-1 select2 inputModelo"
                                        placeholder={"Selecione..."}
                                        options={this.state.modelos.map(it => { return { value: it.Value, label: it.Label } })}
                                        onChange={modelo => this.getVersao(modelo.value, true)}
                                    />

                                </div>
                                <div className="col-md-6 col-12 row form-group">
                                    <label className="col-5 ">Ano/Modelo</label>
                                    <Select
                                        value={this.state.codfipe !== 0 ? this.state.versoes.filter(m => m.Value == this.state.codfipe2).map(m1 => { return { value: m1.Value, label: m1.Label.substring(0, 4) } }) : ''}
                                        className="col-7 my-1 select2 inputAno"
                                        placeholder={"Selecione..."}
                                        options={this.state.versoes.map(it => { return { value: it.Value, label: it.Label.substring(0, 4) } })}
                                        onChange={versao => this.setState({ codfipe2: versao.value })}
                                    />
                                </div>

                                <div className="col-md-6 col-12 row form-group">
                                    <label className="col-5">Ano/Fabricação</label>
                                    <input value={this.state.ano} onKeyPress={this.somenteNumero} type="text" maxLength={4} placeholder={'Ex.: 2020'}
                                        onChange={valor => this.setState({ ano: valor.target.value })}
                                        className="form-control col-7" />
                                </div>

                                <div className="col-md-6 col-12 row form-group">
                                    <label className="col-5">Tipo Combustível</label>
                                    {/*<select className="form-control col-6 my-1" value={this.state.combustivel} onChange={ tp => this.setState({ combustivel: tp.target.value })}>*/}
                                    {/*    <option value="0">Selecione...</option>*/}
                                    {/*    {*/}
                                    {/*        this.state.tiposcomb.map(tipo => <option key={tipo.id} value={tipo.id}>{tipo.nome}</option>)*/}
                                    {/*    }*/}
                                    {/*</select>*/}
                                    <Select
                                        value={this.state.combustivel !== null ? this.state.tiposcomb.filter(tipo => tipo.id === this.state.combustivel).map(m1 => { return { value: m1.id, label: m1.nome } }) : ''}
                                        className="col-7 my-1 select2 inputTipoComb"
                                        placeholder={"Selecione..."}
                                        options={this.state.tiposcomb.map(it => { return { value: it.id, label: it.nome } })}
                                        onChange={comb => this.setState({ combustivel: comb.value })}
                                    />
                                </div>

                                <div className="col-md-6 col-12 row form-group">
                                    <label className="col-5">KM</label>

                                    <IntlCurrencyInput
                                        value={this.state.km}
                                        className="form-control col-7"
                                        onChange={(e, v, mask) => this.setState({ km: mask })}
                                        currency="BRL" config={{
                                            locale: "pt-BR",
                                            formats: {
                                                number: {
                                                    BRL: {
                                                        currency: "BRL",
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                    },
                                                },
                                            }
                                        }}
                                    />
                                    {/*<CurrencyInput allowEmpty={true} decimalSeparator="," thousandSeparator="." value={this.state.km} className="form-control col-6" onChangeEvent={ km => this.setState({ km: km.target.value })} placeholder="Valor da Total" />*/}

                                    {/*<CurrencyInput allowEmpty={true} decimalSeparator="," thousandSeparator="." value={this.state.km} className="form-control col-6" onChangeEvent={ km => this.setState({ km: km.target.value })} placeholder="Valor da Total" />*/}
                                </div>

                                <div className="col-md-6 col-12 row form-group">
                                    <label className="col-5">Cor</label>
                                    {/*<select value={this.state.cor} className="form-control col-6 my-1" onChange={ cor => this.setState({ cor: cor.target.value })}>*/}
                                    {/*    <option value="0">Selecione...</option>*/}
                                    {/*    {*/}
                                    {/*        this.state.cores.map(cor => <option key={cor.id} value={cor.id}>{cor.nome}</option>)*/}
                                    {/*    }*/}
                                    {/*</select>*/}
                                    <Select
                                        value={this.state.cor !== null ? this.state.cores.filter(cor => cor.id === this.state.cor).map(m1 => { return { value: m1.id, label: m1.nome } }) : ''}
                                        className="col-7 my-1 select2 inputCor"
                                        placeholder={"Selecione"}
                                        options={this.state.cores.map(it => { return { value: it.id, label: it.nome } })}
                                        onChange={cor => this.setState({ cor: cor.value })}
                                    />
                                </div>

                                <div className="col-md-6 col-12 row form-group">
                                    <label className="col-5">Valor R$</label>
                                    <IntlCurrencyInput
                                        value={this.state.valor}
                                        className="form-control col-7"
                                        onChange={(e, v, mask) => this.setState({ valor: mask })}
                                        currency="BRL" config={{
                                            locale: "pt-BR",
                                            formats: {
                                                number: {
                                                    BRL: {
                                                        currency: "BRL",
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    },
                                                },
                                            }
                                        }}
                                    />
                                    {/*<CurrencyInput allowEmpty={true} decimalSeparator="," thousandSeparator="." value={this.state.valor} className="form-control col-6" onChangeEvent={ valor => this.setState({ valor: valor.target.value })} placeholder="Valor da Total" />*/}
                                </div>

                                {/*}  <div className="anotherBrowser col-md-6 col-12 row form-group">
                            <label className="col-5">Prazo do Anúncio</label>
                            <input value={this.state.prazo} maxLength="10" min={this.state.minDate.toString()} type="date" onChange={valor => this.setState({ prazo : valor.target.value})} className="imputdata1 form-control col-6" readOnly/>
                            <input min={this.state.minDate.toString()} type="date" onChange={valor => this.setState({ prazo : valor.target.value})} className="imputdata2 form-control col-7" />
                        </div>
                        <div className="firefox col-md-6 col-12 row form-group">
                            <label className="col-5">Prazo do Anúncio</label>
                            <input value={this.state.prazo} maxLength="10" min={this.state.minDate.toString()} type="date" onChange={valor => this.setState({ prazo : valor.target.value})} className="imputdata1 form-control col-6" readOnly/>
                            <input min={this.state.minDate.toString()} type="date" onChange={valor => this.setState({ prazo : valor.target.value})}  className="imputdata2 form-control col-7" />
                            <img className="calendarImgFirefox" src={calendario}/>
                                </div> */}

                                <div className="col-md-12 col-12 row form-group campoobservacao">
                                    <label className="col-2">Obs.:</label>

                                    <textarea type="text" value={this.state.observacao} onChange={obs => this.setState({ observacao: obs.target.value })} className="form-control col-10" />
                                </div>

                                <div className="col-12 mt-2 row form-group">
                                    <label className="col-12">Fotos do Anuncio (A ordem das fotos será a mesma da exibição)</label>
                                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((foto, idx) =>
                                        <div key={idx} className="foto my-2 col-md-6 col-lg-4">
                                            <div>
                                                <label> + </label>
                                                <label> Foto {idx + 1}</label>
                                                <input multiple={true} type="file" className="inputfile" onChange={(event, i) => this.uploadImagem(event, idx)} />
                                                <div className={'imgCadastro'}>
                                                    <img className="fotoSelecionada" src={this.state.fotos[idx]} alt="" />
                                                </div>

                                            </div>
                                            {this.state.fotos[idx] ? <button type={'button'} onClick={() => this.removeImagem(idx, this.state.fotos[idx])} className={"btn btn-block btn-sm btn-light text-danger"}>Remover {idx + 1}</button> : ''}
                                        </div>
                                    )}
                                </div>
                                <label className="col-12">Características</label>
                                {
                                    this.state.caractSelec.map(item =>
                                        <div key={item.id} className="col-md-6 col-12">
                                            <input type="checkbox" defaultChecked={item.check} value={item.id} onClick={() => this.setCaracteristica(item.id)} /> <span className="mt-2">{item.nome}</span>
                                        </div>
                                    )
                                }
                            </div>
                            <button type="button" onClick={() => this.criarAnuncio()} className="btn mt-3 btn-block btn-warning">Editar Anuncio</button>
                        </form>
                    </div>
                </div>
                <div>
                    <img height="1" width="1"
                        src="https://www.facebook.com/tr?id=1438661459663519?ev=PageView?noscript=1" />
                </div>
            </div>
        )
    }


}

export default withRouter(Editar);
