import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom'
import HttpsRedirect from 'react-https-redirect';
import { inicializarFirebase, receberMensagem } from './config/push-notifications';

ReactDOM.render(
    <BrowserRouter>
        <HttpsRedirect>
            <App />
        </HttpsRedirect>

    </BrowserRouter>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
inicializarFirebase();
serviceWorker.unregister();
