import React from 'react';
import './Login.css';
import logo from '../../../assets/logos/logos vende tudo/200 x 84/LOGO VENDE TUDO.png'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import { BASE_URL_DJANGO , BASE_URL_PHP } from '../../../config/api'
import { setUser , getTokenFirebase, getUser , getToken, removeUser } from '../../../config/local'
import Load from '../../../components/loading/load';
import FaceLogin from "../../../components/facebookLogin/FacebookLogin";
import GLogin from "../../../components/googleLogin/GoogleLogin"

class Login extends React.Component {


    state = {
        email : '',
        senha: '',
        loading: false,
        mostrar: false
    }

    singin = ()=>{
        this.setState({ loading : true })
        axios.post(`${BASE_URL_PHP}/api/login`,this.state)
            .then( async res  => {
                await this.setState({ loading : false });
                await setUser(res.data);
                await this.salvarToken(res.data.id);
                await this.props.history.push("/");
                await this.setState({ loading : false });
            })
            .catch( err =>{
                this.setState({ loading : false });
                removeUser();
                alert('Por favor, verifique os dados inseridos e tente novamente.');
            })
    }

    salvarToken = (userId) =>{
        axios.post(`${BASE_URL_DJANGO}/firebase-register/`,{
            idFirebase: getTokenFirebase(),
            user: userId
        })
            .then( res  => {

            })
            .catch( err =>{
                this.setState({ loading : false });
                return
            })
    }

    responseGoogle = (response) => {
        console.log(response);
    }

    render(){
        return (
            <div className="login">
                <div className="loginContent p-5 mx-1 text-center shadow border">
                    <Load loading={this.state.loading} />
                    <a href="/"><img src={logo} className="img-fluid" width="200"/></a>
                    <div>
                        <h4 className="my-3">Acesse a sua conta</h4>
                        { this.email }
                        <form>
                            {/* <button className="btn  btn-outline-info mt-1 btnSocial">ENTRAR COM FACEBOOK</button>
              <GoogleLogin
                className="btn btnSocial mt-2"
                clientId="327384440534-4gjvtgq1kdjcn007fogpsv12imgt02d5.apps.googleusercontent.com"
                buttonText="ENTRAR COM GOOGLE"
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
                cookiePolicy={'single_host_origin'}
              /> */}
                            {/* <button className="btn  btn-outline-info mt-1 btnSocial">ENTRAR COM GOOGLE</button> */}
                            {/* <div className="loginOu my-3">
                <hr/>OU<hr/>
              </div> */}
                            <input placeholder="E-mail" type="email" onChange={ (email) => {  this.setState({ email: email.target.value  }) } }/>
                            <div className={'d-flex flex-column'}>
                                <input placeholder="Senha" type={this.state.mostrar ? 'text': 'password'} onChange={ (senha) => {  this.setState({ senha: senha.target.value  }) } }/>
                                <button className={"eyeRecuperar"} type={'button'} onClick={() => this.setState({ mostrar: !this.state.mostrar })}>  <i className={ !this.state.mostrar ? 'fas fa-eye' : 'fas fa-eye-slash'}></i> </button>
                            </div>


                            <a onClick={this.singin} className="btn btn-warning my-3"><b>ENTRAR</b></a>
                            {/*<h4>OU</h4>*/}
                            {/*<FaceLogin className={"btnSocial"}/>*/}
                            {/*<GLogin className={"btnSocial"} />*/}
                            <div className="loginConta">
                                <span>Não é cadastrado? <Link to="/cadastro">Cadastre-se</Link> </span>
                            </div>
                            <div className="loginConta">
                                <span><Link to="/redefinirsenha/0">Esqueci minha senha</Link> </span>
                            </div>
                        </form>
                    </div>

                </div>
                <div>
          <img height="1" width="1"
            src="https://www.facebook.com/tr?id=1438661459663519?ev=PageView?noscript=1" />
        </div>
            </div>
        );
    }

}


export default withRouter(Login);