import React from 'react'
import './Notificacoes.css'
import ReactDOM from "react-dom";
import axios from 'axios'
import {withRouter} from 'react-router-dom'
import {BASE_URL_DJANGO} from "../../config/api";
import { getUser } from "../../config/local";
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

class Notificacoes extends React.Component {
    constructor(props) {
        super(props);
    }

    state ={
        notificacoes: [],
        right: false
    }

    getNotificacoes = async () =>{
        const user = await getUser();
        axios.get(`${BASE_URL_DJANGO}/notificacoes/?user=${user.id}`).then( res => this.setState({ notificacoes : res.data }))
            .catch(err => alert('Não foi possível carregar as notificações..'))
    }

    componentDidMount() {
        this.getNotificacoes()
    }

    abrirLink = async (link,id) =>{
        await this.props.closeButton()
        if(link.trim() !== ""){
            await axios.delete(`${BASE_URL_DJANGO}/notificacoes/${id}/`).then( res => this.getNotificacoes() )
                .catch(err => alert('Não foi possível carregar a notificação.'))
            await this.props.history.push(`/chat`)
            await this.props.history.push(`/conversa${link}`)
        }else{
            await alert("Não foi possível buscar os dados da notificação.")
        }
    }



    renderNotificacao = () =>{
        return this.state.notificacoes.map(
            not =>
                <>
                    <div className={"notificacaoItem"}>
                        <div className={"notItemHeader"} onClick={() => this.abrirLink(not.url_web || '', not.id)}>
                            <b className={"text-dark"}>{not.title || ''}</b>
                            <p>{not.description || ''}</p>
                        </div>
                    </div>
                </>
        )
    }

    render() {
        const useStyles = makeStyles({
            list: {
                width: 250,
            },
            fullList: {
                width: 'auto',
            },
        });

        return (
            this.props.visible ?
            <React.Fragment key={true}>
                <Drawer anchor={'right'} open={this.props.visible}>
                    <div className="notificacoesHeader">
                        <h6 className={"p-2 ml-2 pt-3"}>Notificações</h6>
                        {/*<button type={"button"} className={"btn btn-sm btn-danger px-4 my-2 mr-2"} onClick={() => this.props.closeButton()}><i className={'fas fa-arrow-right'}></i></button>*/}
                    </div>
                    <div className={"notificacaoList"}>
                        {this.renderNotificacao()}
                    </div>
                </Drawer>
            </React.Fragment> : ''
        )

    }
}

export default withRouter(Notificacoes);
