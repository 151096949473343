import React from 'react'
import './Privacidade.css';
import axios from 'axios'
import { BASE_URL_PHP } from "../../../config/api";

class Privacidade extends React.Component{

    state ={
        dados: {},
        topicos: []
    }

    componentDidMount = () =>{
        this.getPrivacidade();
    }

    getPrivacidade = () =>{
        axios.get(`${BASE_URL_PHP}/api/privacidade`).then(res => this.setState({ 'dados' : res.data, 'topicos': res.data.topicos}, ()=> console.log(res.data) ))
            .catch(err => console.log(err))
    }

    render (){
        return(
            <div className="privacidade">
                <h1 className="p-4 bg-warn">{this.state.dados.titulo}</h1>
                <h5 className="py-2">{this.state.dados.sinopse}</h5>
                <h5 className="pt-2">{this.state.dados.tituloTopicos}</h5>
                <ul>
                    {this.state.topicos.map(top => <li>{top}</li>)}
                </ul>
                <div>
          <img height="1" width="1"
            src="https://www.facebook.com/tr?id=1438661459663519?ev=PageView?noscript=1" />
        </div>
            </div>
        )
    }
}

export  default Privacidade;