import React from "react";
import { withRouter } from 'react-router-dom'
import "./FaleConosco.css"
import logo from "../../assets/logos/logos vende tudo/200 x 84/LOGO VENDE TUDO.png";
import InputMask from "react-input-mask";
import axios from 'axios';
import {BASE_URL_DJANGO} from "../../config/api";
import { getUser } from "../../config/local";
import Load from "../../components/loading/load";

class FaleConosco extends React.Component {

    state = {
        nome: "",
        email: "",
        telefone: "",
        mensagem: "--",
        loading: false,
        user: {}
    }

    componentDidMount = async ()  =>{

        const user = await getUser();
        if(user){
            await this.setState({ user : user })
            await this.setState({
                nome: user.nome,
                email: user.email,
                telefone: `${user.telefone ? user.telefone.ddd : ''}${user.telefone? user.telefone.numero : ''}`
            })
        }

    }

    enviarContato = () => {
        this.setState({ loading: true })
        const valid = this.state.nome.trim() !== ""
            && this.state.email.trim() !== ""
            && this.state.telefone.trim() !== ""

        if(!valid){
            alert("Verifique os dados inseridos e tente novamente.")
            this.setState({loading: false})
            return
        }
        axios.post(`${BASE_URL_DJANGO}/fale-conosco/`,this.state)
            .then( res => {
                this.setState({ loading: false})
                // alert("Nossa equipe de analistas entrará em contato com você em breve!")
                window.open('https://wa.me/5563992143171','_blank')
                this.setState({
                    nome: '',
                    email: '',
                    telefone: '',
                    mensagem: ''
                })

                this.props.history.push("/")
            })
            .catch(err => {
                alert("Não foi possível enviar a mensagem.")
                this.setState({ loading: false })
                return
            })

    }

    render() {
        return (
            <>
               <div id={"contato"}>
                   <Load loading={this.state.loading}/>
                   <div className={"contatoContent p-5"}>
                       <div className={"text-center"}>
                           <a href="/"><img src={logo} className="img-fluid" width="200"/></a>
                       </div>
                       <h2>Fale Conosco</h2>
                       <h6 className="my-2">Nos mande sua mensagem e um dos nossos analistas <br/> entrará em contato.</h6>
                       <form>
                           <input value={this.state.nome} placeholder="Nome" type="text" type="text" onChange={v => this.setState({ nome: v.target.value})}/>
                           <input value={this.state.email} placeholder="E-mail" type="email" onChange={v => this.setState({ email: v.target.value})} />
                           <InputMask value={this.state.telefone}  placeholder="Telefone"  mask={ "(99)99999-9999" } onChange={tel => this.setState({ telefone: tel.target.value})} />
                           {/*<textarea value={this.state.mensagem} placeholder="Escreva sua mensagem" rows={6} type="text" onChange={v => this.setState({ mensagem: v.target.value})}></textarea>*/}
                           <button type="button" onClick={() => this.enviarContato()} className="btn btn-warning my-3">Enviar</button>
                       </form>
                   </div>
                   <div>
          <img height="1" width="1"
            src="https://www.facebook.com/tr?id=1438661459663519?ev=PageView?noscript=1" />
        </div>
               </div>
            </>
        )
    }
}

export default withRouter(FaleConosco)