import React, { Component } from 'react'
import './Consulta.css'

function Consulta(){
    return(
        <div className="consulta">
            <h1>Tabela Fipe</h1>
            <h3>Consulte o preço de carros novos e usados</h3>
            <div className="container">
                <div className="row p-4">
                    <div className="col-md-4 my-0 p-0">
                        <select className="form-control">
                            <option>Marca</option>
                        </select>
                    </div>
                    <div className="col-md-3 my-0 p-0">
                        <select className="form-control">
                            <option>Modelo</option>
                        </select>
                    </div>
                    <div className="col-md-2 my-0 p-0">
                        <select className="form-control">
                            <option>Ano</option>
                        </select>
                    </div>
                    <div className="col-md-3 my-0 p-0">
                        <select className="form-control">
                            <option>Versão</option>
                        </select>
                    </div>
                    <div className="col-md-3 my-0 p-0">
                        <select className="form-control">
                            <option>Tocantins</option>
                        </select>
                    </div>
                    <div className="col-md-6 my-0 p-0">
                        <select className="form-control">
                            <option>Nome</option>
                        </select>
                    </div>
                    <div className="col-md-3 my-0 p-0">
                        <select className="form-control">
                            <option>Email</option>
                        </select>
                    </div>
                    <div className="col-md-3 my-4 p-0">
                        <button className="btn btn-info form-control">
                           Preço de Venda
                        </button>
                    </div>
                    <div className="col-md-3 my-4 p-0">
                        <button className="btn-outline-info form-control">Preço de Venda</button>
                    </div>
                    <div className="col-md-12 my-4 p-0 consultaAvaliarDiv">
                        <div>
                            <a href="/">Tabela Fipe Motos </a>
                            <a href="/">Tabela Fipe Carros</a>
                        </div>
                            
                        <button className="btn btn-warning">Avaliar</button>
                    </div>
                </div>
            </div>
            <div>
          <img height="1" width="1"
            src="https://www.facebook.com/tr?id=1438661459663519?ev=PageView?noscript=1" />
        </div>
        </div>
    )
}

export default Consulta;