import React, {useState } from 'react'
import './QueroComprar.css'
import anuncios from '../../../assets/images/carrinho.png'
import anuncio from '../../../assets/images/sandero.jpeg'
import axios from 'axios'
import { BASE_URL_DJANGO } from "../../../config/api";
import {getUser, setVeiculo} from "../../../config/local";
import { withRouter } from 'react-router-dom'
import Load from '../../../components/loading/load'
import {Button, Modal} from "react-bootstrap";
import CurrencyInput from "react-currency-input";
import IntlCurrencyInput from "react-intl-currency-input";
import whats from '../../../assets/images/whatsapp.png'
import email from '../../../assets/images/email.png'
import phone from '../../../assets/images/phone.png'
import fundo from "../../../assets/images/fundo_1.png";

class  QueroComprar extends React.Component{

    state = {
        anuncios:[],
        loading: false,
        whats: 'api'
    }

    componentDidMount() {
        this.getRelatorio()
        this.verificarDispositivo()
    }

    getRelatorio = () =>{
        this.setState({ loading: true })
        const url = `${BASE_URL_DJANGO}/quero-comprar/`
        axios.get(url)
            .then(res => {
                this.setState({ anuncios : res.data.data , loading: false })
            })
            .catch(err => {
                this.setState({ loading: false});
                alert('Não foi possível encontrar seus anuncios. Por favor, tente novamente mais tarde.')
            })
    }
    numberToReal = (numero) => {
        var numero = numero.toFixed(2).split('.');
        numero[0] =  numero[0].split(/(?=(?:...)*$)/).join('.');
        return numero.join(',');
    }
    verificarDispositivo = async () =>{

        if (window.innerWidth < 620 ) {
            await this.setState({ whats: 'api'})
        } else {
            await this.setState({ whats: 'web'})
        }
    }
    formatarDescricao = desc =>{
        const descricao = `${desc}`
        return descricao.split("\n").map((item,idx) => <span key={idx}>{item}<br/></span> )
    }


    render(){
        return(
            <>
                <Load loading={this.state.loading} />
                <img src={fundo} className={"imgBackground"} alt=""/>
            <div className="resumo mx-auto">
                <div className="resumoContainer">
                    <div className="resumoButtons d-flex flex-column p-3 mx-auto my-1">
                        <h2 className={'text-white mr-3'}>Relatório de Quero Comprar</h2>
                    </div>
                    <div className="resumoConteudo contentQueroComprar d-flex flex-row flex-wrap mx-auto p-3">

                        {
                            !this.state.expirados ?
                                this.state.anuncios.map(
                                    item =>
                                        <div className="btn m-1 text-left bg-light rounded-lg btnCard">
                                            <button type={"button"} className="publicacaoCard btn m-1 text-left bg-light rounded-lg">
                                                    Marca: {item.infoCar.Marca} <br/>
                                                    Modelo: { item.infoCar !== null ? item.infoCar.Modelo : '-'}
                                                <div className="">
                                                        Ano: { item.infoCar !== null ? item.infoCar.AnoModelo : '-'} <br/>
                                                        Câmbio: {item.flautomatico ? 'Automático' : 'Manual'}<br/>
                                                        Valor: R$ { this.numberToReal( parseFloat(item.valor) )}  <br/>
                                                        Observação: {this.formatarDescricao(item.observacao)} <br/>
                                                        Nome: {item.user_info.name} <br/>

                                                        <div className={"divContato"}>
                                                            E-mail: <br/> {item.user_info.email}<a href={`mailto:${item.user_info.email}`} data-toggle="tooltip" title={'Entre em contato por e-mail'} className={"p-1 mx-2 "}><img src={email} className={"iconContato"} width={16} height={16}
                                                                                                                                                                   alt=""/></a> <br/>
                                                            Telefone: <br/> {item.user_info.telefone} <a target={"_blank"} data-toggle="tooltip" title={'Entre em contato por ligação'} href={`tel:${item.user_info.telefone.replace("(","").replace(")","").replace(" ","").replace("-","").replace("_")}`} className={" p-1 mx-2 d-md-none"}><img src={phone} className={"iconContato"} alt=""/></a> <a target={"_blank"} data-toggle="tooltip" title={'Entre em contato por whatsapp'} href={`https://${this.state.whats}.whatsapp.com/send?phone=55${item.user_info.telefone.replace("(","").replace(")","").replace(" ","").replace("-","").replace("_")}`} className={" p-1 mx-2 "}><img src={whats} className={"iconContato"} alt=""/></a> <br/>
                                                        </div>
                                                        <hr/>
                                                        Data: {item.get_datetime}
                                                </div>
                                            </button>
                                        </div>
                                ):
                                this.state.anunciosExpirados.map(
                                    item =>
                                        <div className="btn m-1 text-left bg-light rounded-lg btnCard">
                                            <button onClick={() => this.verCarro(item)} className="publicacaoCard btn m-1 text-left bg-light rounded-lg">
                                                <b>
                                                    { item.infoCar !== null ? item.infoCar.Modelo : '-'}
                                                </b> <br/>
                                                <div className="d-flex">
                                                    {item.image_anuncio.length > 0 ? <img src={item.image_anuncio[0].foto} className="py-2" height="100"/> : "" }
                                                    {/**/}
                                                    <p className="p-2">
                                                        <strong>R$ {item.valor}</strong> <br/>
                                                        { item.infoCar !== null ? item.infoCar.AnoModelo : '-'} <br/>
                                                        {item.cor.nome} <br/>
                                                        {item.km}Km <br/>
                                                    </p>
                                                </div>
                                            </button>
                                            {/*<div className="w-100 text-right">*/}
                                            {/*    <button className="btn btn-info" onClick={() => this.abrirModal(item.id)}>Desativar</button>*/}
                                            {/*    <button className="btn btn-warning ml-2" onClick={() => this.setState({ extensor: true, anuncio: item.id })}>Estender prazos</button>*/}
                                            {/*    /!*{item.usuario} -  <i>{item.hora.getDate()}/{item.hora.getMonth() + 1}/{item.hora.getFullYear()} {item.hora.getHours()}:{item.hora.getMinutes()}:{item.hora.getSeconds()}</i>*!/*/}
                                            {/*</div>*/}
                                        </div>

                                )}
                    </div>
                </div>
                <div>
          <img height="1" width="1"
            src="https://www.facebook.com/tr?id=1438661459663519?ev=PageView?noscript=1" />
        </div>
            </div>
            </>
        )
    }



}

export default withRouter(QueroComprar);
