export const setUser = user => window.localStorage.setItem('userData',JSON.stringify(user))

export const getUser = () =>  JSON.parse(localStorage.getItem('userData'))

export const getToken = () => 'Bearer ' + getUser().access_token

export const getTokenFirebase = () => localStorage.getItem('tokenFire')

export const setTokenFirebase = (token) => localStorage.setItem('tokenFire', token )

export const removeTokenFirebase = () => localStorage.removeItem('tokenFire')

export const setVeiculo = anuncio => localStorage.setItem('veiculo', JSON.stringify(anuncio))

export const getVeiculo = () => JSON.parse(localStorage.getItem('veiculo'))

export const removeVeiculo = () => localStorage.removeItem('veiculo')

export const verificarDispositivo = () => JSON.parse(localStorage.getItem('mobile'))

export  const setMobile = (mobile) => localStorage.setItem('mobile', JSON.stringify(mobile))

export const removeUser = () => {
  localStorage.removeItem('userData')
  return null;
} 

export default{ setUser ,getTokenFirebase, getUser, getToken, setTokenFirebase,removeTokenFirebase, removeUser, setVeiculo, removeVeiculo, setMobile, verificarDispositivo }