import React from "react";
import GoogleLogin  from  "react-google-login";
import "./GoogleLogin.css"

class GLogin extends React.Component{

    responseGoogle = (res) =>{
        console.log(res)
    }

    render() {
        return(
            <GoogleLogin
                clientId="241591358298-3smuocuokmanu9gbhs5389uom959dnma.apps.googleusercontent.com"
                buttonText="Continue com Google"
                onSuccess={(res) => this.responseGoogle(res)}
                onFailure={(res) => this.responseGoogle(res)}
                cookiePolicy={'single_host_origin'}

            />
        )
    }

}

export default GLogin