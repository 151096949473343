import React from 'react';
import './Cadastro.css';
import logo from '../../../assets/logos/logos vende tudo/200 x 84/LOGO VENDE TUDO.png'
import { Link , withRouter } from 'react-router-dom'
import axios from 'axios'
import {BASE_URL_DJANGO, BASE_URL_PHP, BASE_URL_GESTOR} from '../../../config/api'
import InputMask from "react-input-mask"
import CurrencyInput from "react-currency-input";
import Load from '../../../components/loading/load'
import GoogleLogin from "../../../components/googleLogin/GoogleLogin"
import FaceLogin from "../../../components/facebookLogin/FacebookLogin";
import GLogin from "../../../components/googleLogin/GoogleLogin";
import Select from "react-select";
import IntlCurrencyInput from "react-intl-currency-input";


class Cadastro extends React.Component {


  state ={
    nome: '',
    email: '',
    senha: '',
    id_cidade: '',
    idorigem: [],
    estados : [],
    cidades:[],
    cpf: '',
    telefone: '',
    pessoaJuri: false,
    loading: false,
    perc_fipe: '',
    mostrar: false,
    aceite: false,
    id_origem: ''
  }

  getEstados = () =>{
    this.setState({ loading: true })
    axios.get(`${BASE_URL_GESTOR}/api/estados/`)
        .then(res =>{
          this.setState({ estados : res.data, loading: false})
        } )
        .catch(err => {
          alert("Não foi possível carregar a lista de estados.Tente novamente mais tarde")
          this.setState({ loading: false})
        })
  }

  getCidades = (idestado) =>{
    if(idestado == 0){
       return this.setState({id_cidade: "0", cidades: []})
    }
    this.setState({ loading: true })

    axios.get(`${BASE_URL_GESTOR}/api/estados/${idestado}/cidades/`)
        .then(res => this.setState({ cidades : res.data , loading: false}))
        .catch(err => {
          alert("Não foi possível carregar a lista de cidades.")
          this.setState({ loading: false})
        })
  }

  getOrigem = () =>{
    this.setState({ loading: true })
    axios.get(`${BASE_URL_PHP}/api/origens`)
        .then(res => this.setState({ idorigem : res.data, loading: false}))
        .catch(err => {
          alert("Não foi possível carregar a lista de origens.Tente novamente mais tarde")
          this.setState({ loading: false})
        })
  }

  createUser = () =>{
    // return console.log(this.state)
    this.setState({ loading: true })
    const valid = this.state.nome.trim() !== ''
        // && this.state.email.trim() !== ''
        // && this.state.id_cidade !== null
        // && this.state.id_origem !== null
        // && this.state.cpf.trim() !== ""

    if(!valid){
      this.setState({ loading: false })
      alert('Prencha todos os Dados por favor!');
    }else{
      const telefoneNumero = this.state.telefone
          .replace("_","")
          .replace("-","")
          .replace("(","")
          .replace(")","")
          .split('');
      const ddd = `${telefoneNumero[0]}${telefoneNumero[1]}`;
      let telefone = "";
      telefoneNumero.map((n,ind)=>{
        if(ind > 1) telefone += n
      })

      let user = {
        nome: this.state.nome,
        email: this.state.email,
        senha: this.state.senha,
        cpf_cnpj: this.state.cpf,
        ddd: ddd,
        telefone: telefone,
        aceite: this.state.aceite
      }

      if(this.state.id_origem !== ''){
        user.idorigem = parseInt(this.state.id_origem)
      }

      if(this.state.id_cidade !== ''){
        user.idcidade = parseInt(this.state.id_cidade)
      }

     
      this.state.pessoaJuri ? user.perc_fipe = this.state.perc_fipe.replace("% ","").replace(".","").replace(",",".") : user.perc_fipe = '';
      this.state.pessoaJuri ? user.id_tipo = 2 : user.id_tipo = 1

      var data = new Date();
      var dia = String(data. getDate()). padStart(2 ,'0');
      var mes = String(data. getMonth() + 1). padStart(2, '0');
      var ano = data. getFullYear();
      var dataAtual = ano + '-' + mes + '-' + dia;

  

         /*RESPONSAVEL POR ENVIAR OS DADOS PARA O CRM STEVE */
           /*http://191.252.195.49:8080/steve/steveApi/cliente/salvarcadastrojobs/{nome_cliente}/{cpf}/{data_compra}/{email}/{telefone}/{idempresa}/*/

           let steve = {
            nome_cliente: this.state.nome,
            cpf_cnpj: this.state.cpf,
            datapontos: dataAtual,
            email: this.state.email,
           telefone_cel:"("+ddd+")"+telefone,
            idEmpresa: 43
          }


           axios.post('https://steve.dev.br/steve/steveApi/cliente/salvarjobs/',steve)
          .then(res => {
            
            }
           )
           .catch(err => {
           } )


      axios.post(`${BASE_URL_PHP}/api/usuarios`, user)
          .then(res => {
            this.setState({ loading: false })
           
            alert('Cadastro realizado com sucesso.')
            this.props.history.push("/login")}
          )
          .catch(err => {
            this.setState({ loading: false })
            let erros = '\n';
            const campos = Object.keys(err.response.data).map(it => {
              return erros = erros + '- ' + err.response.data[it] + '\n'
            })
            alert("Não foi possível criar o seu usuário. Por favor, verifique: " + erros)
          } )



 
    }
  }


  componentWillMount = () =>{
    this.getEstados();
    this.getOrigem();
  }



  render(){
    let juridica = false
    return (
        <div className="cadastroLogin">
          <Load loading={this.state.loading} />
          <div className="cadastroLoginContent p-5 text-center shadow border">
            <a href="/"><img src={logo} className="img-fluid" width="200"/></a>
            <div className="text-left">
              <h4 className="my-3 text-default">Cadastro</h4>
              <h6 className="my-2">Insira seu e-mail, nome e senha para criar seu cadastro</h6>
              <form>
                <input placeholder="E-mail" type="email" className={"inputLogin"} onChange={email => this.setState({ email: email.target.value })}/>
                <input placeholder="Nome" className={"inputLogin"} type="text" onChange={ nome => this.setState({ nome: nome.target.value})}  />
                <InputMask  placeholder="Telefone" className={"inputLogin"} mask={ "(99)99999-9999" } onChange={tel => this.setState({ telefone: tel.target.value})} />
                {/*<select placeholder="Estado" onChange={ estado => this.getCidades(estado.target.value )}>*/}
                {/*  <option value="0" >Selecione o estado...</option>*/}
                {/*  {*/}
                {/*    this.state.estados.map(estado => <option value={estado.id}>{estado.nomeEstado}</option>)*/}
                {/*  }*/}
                {/*</select>*/}
                <Select
                    value={this.state.id_origem !== 0 ? this.state.idorigem.filter(origem => origem.id === this.state.id_origem ).map(m1 => { return { value: m1.id, label: m1.descricao  } }): ''}
                    className=" my-1 select2 borderInput"
                    placeholder={"Como você conheceu nosso site ?"}
                    options={this.state.idorigem.map(it => { return { value: it.id, label: it.descricao }})}
                    onChange={idorigem => this.setState({id_origem : idorigem.value})}
                    disabled={!this.state.edit}
                />
                <Select
                    className=" my-1 select2 borderInput"
                    placeholder={"Selecione Estado"}
                    options={this.state.estados.map(it => { return { value: it.id, label: it.nomeEstado }})}
                    onChange={estado => this.getCidades(estado.value)}
                    disabled={!this.state.edit}
                />
                {/*<select value={this.state.id_cidade} placeholder="Cidade" onChange={ cidade => this.setState({ id_cidade : cidade.target.value })}>*/}
                {/*  <option value="0">Selecione a cidade...</option>*/}
                {/*  {*/}
                {/*    this.state.cidades.map(cidade => <option value={cidade.id}>{cidade.nomeCidade}</option>)*/}
                {/*  }*/}
                {/*</select>*/}
                <Select
                    value={this.state.id_cidade !== 0 ? this.state.cidades.filter(cidade => cidade.id === this.state.id_cidade ).map(m1 => { return { value: m1.id, label: m1.nomeCidade  } }) : ''}
                    className=" my-1 select2 borderInput"
                    placeholder={"Selecione Cidade"}
                    options={this.state.cidades.map(it => { return { value: it.id, label: it.nomeCidade }})}
                    onChange={cidade => this.setState({ id_cidade: cidade.value} )}
                    disabled={!this.state.edit}
                />
                <div className={'d-flex flex-column'}>
                  <input placeholder="Senha" className={"inputLogin"} type={this.state.mostrar ? 'text' : 'password'} onChange={ senha => this.setState({ senha: senha.target.value })}/>
                  <button className={"eyeCadastro"} type={'button'} onClick={() => this.setState({ mostrar: !this.state.mostrar })}>  <i className={ !this.state.mostrar ? 'fas fa-eye' : 'fas fa-eye-slash'}></i> </button>
                </div>

                <div className="cadastroLoginCheck">
                  <input value={this.state.pessoaJuri} className={"inputLogin"} type="checkbox"  onChange={() => {
                    this.setState({ pessoaJuri : !this.state.pessoaJuri })
                  }} label="Desejo"/>
                  <label style={ { zIndex: 0}}>Pessoa Jurídica?</label>
                </div>
                <InputMask placeholder={ this.state.pessoaJuri ? 'CNPJ' : 'CPF'} className={"inputLogin"}  onChange={ vl => this.setState({ cpf: vl.target.value }) } mask={ this.state.pessoaJuri ? "99.999.999/9999-99" : "999.999.999-99" } />
                {this.state.pessoaJuri ?
                    <div>
                      <label className="labelValor">Porcentagem Fipe*</label>
                      {/*<CurrencyInput prefix="% " maxlength="8" allowEmpty={true} decimalSeparator="," thousandSeparator="." value={this.state.perc_fipe} className="form-control" onChangeEvent={ valor => this.setState({ perc_fipe: valor.target.value  })} placeholder="Valor da Repasse" />*/}
                      <IntlCurrencyInput
                          value={this.state.perc_fipe}
                          className="form-control mb-2"
                          onChange={(e,v,mask) => this.setState({ perc_fipe: mask  })}
                          currency="BRL" config={{
                        locale: "pt-BR",
                        formats: {
                          number: {
                            BRL: {
                              currency: "BRL",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            },
                          },
                        }
                      }}
                      />

                    </div> : ''
                }

                <div className="cadastroLoginCheck mt-0">
                  <input value={this.state.aceite} onChange={v => this.setState({ aceite: !this.state.aceite })} type="checkbox"/>
                  <label style={{ zIndex: 0}}>Aceito os<a target={"_blank"} href="/privacidade"> termos de uso e políticas de privacidade.</a></label>
                </div>
                {this.state.pessoaJuri ?
                    <div>
                      <small style={{ fontSize: '10px'}}>*Avisaremos você quando for disponibilizado  um veiculo abaixo do valor <br/> da tabela Fipe cadastrado acima.</small>
                    </div> : ''
                }

                <button type="button" onClick={() => this.createUser()} className="btn btn-warning my-3">Cadastrar</button>
                <div className="cadastroLoginConta">
                  <span>Ja tem conta? Faça seu <Link to="/login">Login</Link> </span>
                </div>

              </form>
            </div>

          </div>
          <div>
          <img height="1" width="1"
            src="https://www.facebook.com/tr?id=1438661459663519?ev=PageView?noscript=1" />
        </div>
        </div>
    );
  }


}

const responseGoogle = (response) => {
  // console.log(response);
}



export default withRouter(Cadastro);
