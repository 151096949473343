import React,{ useState } from 'react'
import './Pessoa.css'

function Pessoa(){
  
  const [step,setStep] = useState(0);

  return(
    <form className="pessoa container text-left my-5">
        <div className="row">
          <div className="col-md-12 row">
            <label className="col-1 my-2 pt-2">Nome</label>
            <input className="form-control col-11" type="text"/>
          </div>
        </div>
        <div className="row p-0">
          <div className="row col-md-6 my-2 ">
            <label className="col-2 pt-2">E-mail</label>
            <input className="form-control col-10" type="text"/>
          </div>
          <div className="row col-md-6 my-2">
            <label className="col-2 pt-2">Telefone</label>
            <input className="form-control col-10" type="text"/>
          </div>
        </div>
        <div className="row">
        {
          step !== 0 ? 
            <div className="col-md-6 my-2 row">
              <label className="col-2">Total</label>
              <input className="form-control col-10" type="text"/>
            </div> : ''
        }
        {
          step !== 0 ? 
            <div className="col-md-6 my-2 row">
              <label className="col-2">Financiar</label>
              <input className="form-control col-10" type="text"/>
            </div> : ''
        }
        </div>
        
        
        
       
        <div className="col-md-12 text-right">
          
          { step !== 0 ?
            <button type="button" onClick={ () => window.location.href='/simular/formulario'} className="btn btn-warning text-white mx-3" >Calcular Parcelas</button> : <button type="button" className="btn btn-warning text-white mx-3" onClick={ () => setStep(1) }>Informar Valores</button>
          }
          
        </div>
        <div>
          <img height="1" width="1"
            src="https://www.facebook.com/tr?id=1438661459663519?ev=PageView?noscript=1" />
        </div>
    </form>
  );
}

export default Pessoa;